import store from '@/store'
import { getEnName } from '@/utils/utils'
export default {
  methods: {
    getName(ojbk, cnName, enName) {
      return store.getters['settings/language'] === 'en'
        ? ojbk[getEnName(cnName, enName)]
        : ojbk[cnName]
    },
  },
}
