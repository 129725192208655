<template>
  <div class="pro_material">
    <el-row :gutter="20" class="num-total">
      <el-col :span="4">
        <h4>{{ orderTotal.tradingVolume || 0 }}</h4>
        <!-- <p>订单成交量</p> -->
        <p>{{ $t('cusDetail.OrderVolume') }}</p>
      </el-col>
      <el-col :span="4">
        <h4>
          <span>$</span>
          {{ orderTotal.tradingMoney || 0 }}
        </h4>
        <!-- <p>订单总金额</p> -->
        <p>{{ $t('cusDetail.TotalOrderAmount') }}</p>
      </el-col>
    </el-row>
    <el-table class="mt15" :data="orderData" border style="width: 100%">
      <el-table-column
        type="index"
        width="50"
        align="center"
        label="#"
      ></el-table-column>
      <!-- label="订单编号" -->
      <el-table-column
        prop="orderCode"
        align="center"
        :label="$t('cusDetail.OrderNumber')"
      >
        <template slot-scope="scope">
          <span
            :class="[scope.row.skip === 1 ? 'blue-text' : '']"
            @click="goToPage(scope.row.orderCode, scope.row.skip)"
          >
            {{ scope.row.orderCode }}
          </span>
        </template>
      </el-table-column>
      <!-- label="订单来源" -->

      <el-table-column
        prop="orderSource"
        align="center"
        :label="$t('cusDetail.OrderSource')"
      ></el-table-column>
      <!-- label="签约日期" -->

      <el-table-column
        align="center"
        prop="contractDate"
        :label="$t('cusDetail.DateOfSigning')"
      ></el-table-column>
      <!-- label="客户代表" -->

      <el-table-column
        align="center"
        prop="businessName"
        :label="$t('cusDetail.CustomerRepresentative')"
      >
        <template slot-scope="scope">
          {{ getName(scope.row, 'businessName') }}
        </template>
      </el-table-column>
      <!-- label="客户订单号" -->

      <el-table-column
        align="center"
        prop="customerOrderCode"
        :label="$t('cusDetail.CustomerOrderNumber')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- label="产品编号" -->

      <el-table-column
        align="center"
        prop="productCode"
        :label="$t('cusDetail.ProductCode')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- label="产品名称" -->

      <el-table-column
        align="center"
        prop="productEn"
        :label="$t('cusDetail.ProductName')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- label="订单金额" -->
      <el-table-column
        align="center"
        prop="totlePrice"
        :label="$t('cusDetail.OrderAmount')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          ${{scope.row.totlePrice}}
        </template>
      </el-table-column>
      <!-- label="订单状态" -->
      <el-table-column
        align="center"
        prop="orderStatus"
        :label="$t('cusDetail.OrderStatus')"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ $t(orderStatus(row.orderStatus)) }}
        </template>
      </el-table-column>
      <!-- label="下单日期" -->

      <el-table-column
        align="center"
        prop="createTime"
        :label="$t('cusDetail.OrderDate')"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>

    <Pagination
      v-show="total > 0"
      :limit.sync="queryForm.pageLe"
      :page.sync="queryForm.pageNo"
      :total="total"
      @pagination="proSelectSalesOrderProductPageVO"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/Index'
  import {
    customerGetOrderInfoVO,
    customerGetOrderInfoPageVO,
  } from '@/api/client-supplier/client-manage'
  import { orderStatusLang } from '@/utils/en-match-zh'
  import nameMixin from '@/utils/name-mixin'
  export default {
    name: 'OrderRecord',
    mixins: [nameMixin],
    components: { Pagination },
    data() {
      return {
        queryForm: {
          pageLe: 10,
          pageNo: 1, //当前页数
        },
        total: 0,
        infoId: '', //产品id
        orderTotal: {}, //订单的一些总计
        orderData: [], //订单的记录列表
      }
    },

    created() {
      if (this.$route.query.customerId) {
        this.infoId = this.$route.query.customerId
        //客户订单记录与订单记录列表
        this.proGetOrderProductVO()
        this.proSelectSalesOrderProductPageVO()
      }
    },
    mounted() {},
    methods: {
      //订单记录
      async proGetOrderProductVO() {
        let response = await customerGetOrderInfoVO({ infoId: this.infoId })
        if ((response.code = '000000')) {
          this.orderTotal = response.data
        }
      },
      //订单记录列表
      async proSelectSalesOrderProductPageVO() {
        let response = await customerGetOrderInfoPageVO({
          ...this.queryForm,
          infoId: this.infoId,
        })
        if ((response.code = '000000')) {
          this.orderData = response.data.data
          this.total = response.data.total
        }
      },
      //订单跳转
      goToPage(code, skip) {
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: {
            orderCode: code,
            noReturn: true,
          },
        })
        if (skip === 1) {
          window.open(routeData.href, '_blank')
        }
      },
      // 查看
      lookClick() {
        return this.$baseMessage(
          '待开发...',
          'error',
          false,
          'erp-hey-message-error'
        )
      },
      //订单状态
      orderStatus(val) {
        return orderStatusLang(val)
      },
    },
  }
</script>

<style scoped lang="scss">
  .num-total {
    padding-left: 15px;
    > .el-col {
      border: 1px solid #ededed;
      padding: 15px 20px;
      border-radius: 2px;
      h4 {
        font-size: 28px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        span {
          font-size: 14px;
          font-weight: normal;
        }
      }
      p {
        margin-top: 5px;
      }
    }
    .el-col + .el-col {
      margin-left: 10px;
    }
  }
  ::v-deep {
    .el-tabs__content {
      padding-left: 50px !important;
    }
  }
</style>
