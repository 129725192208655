var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.addressAddVisible)?_c('el-dialog',{staticClass:"address",attrs:{"title":_vm.$t(_vm.title),"width":"600px","visible":_vm.addressAddVisible,"close-on-click-modal":false,"before-close":_vm.close,"height":"90%"},on:{"update:visible":function($event){_vm.addressAddVisible=$event}}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"100px","label-position":_vm.lang === 'en' ? 'top' : 'left'}},[_c('el-form-item',{attrs:{"prop":"companyName","label":_vm.$t('cusDetail.ReceivingCompany'),"rules":[
        {
          required: true,
          trigger: 'blur',
          message: _vm.$t('placeholder.plsInput'),
        } ]}},[_c('el-input',{attrs:{"maxlength":"50","clearable":""},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}})],1),_c('el-form-item',{attrs:{"prop":"consigneeName","label":_vm.$t('cusDetail.Receiver'),"rules":[
        {
          required: true,
          trigger: 'blur',
          message: _vm.$t('placeholder.plsInput'),
        } ]}},[_c('el-input',{attrs:{"maxlength":"50","clearable":""},model:{value:(_vm.form.consigneeName),callback:function ($$v) {_vm.$set(_vm.form, "consigneeName", $$v)},expression:"form.consigneeName"}})],1),_c('el-form-item',{attrs:{"prop":"consigneePhone","label":_vm.$t('cusDetail.ContactNumber'),"rules":[
        {
          required: true,
          trigger: 'blur',
          message: _vm.$t('placeholder.plsInput'),
        } ]}},[_c('el-input',{attrs:{"maxlength":"50","clearable":""},model:{value:(_vm.form.consigneePhone),callback:function ($$v) {_vm.$set(_vm.form, "consigneePhone", $$v)},expression:"form.consigneePhone"}})],1),_c('el-form-item',{attrs:{"prop":"nation","label":_vm.$t('cusDetail.Country'),"rules":[
        {
          required: true,
          trigger: 'blur',
          message: _vm.$t('placeholder.plsInput'),
        } ]}},[_c('el-input',{attrs:{"maxlength":"50","clearable":""},model:{value:(_vm.form.nation),callback:function ($$v) {_vm.$set(_vm.form, "nation", $$v)},expression:"form.nation"}})],1),_c('el-form-item',{attrs:{"prop":"postcode","label":_vm.$t('cusDetail.ZipCode'),"rules":[
        {
          required: true,
          trigger: 'blur',
          message: _vm.$t('placeholder.plsInput'),
        } ]}},[_c('el-input',{attrs:{"maxlength":"50","clearable":""},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}})],1),_c('el-form-item',{attrs:{"prop":"province","label":_vm.$t('cusDetail.StateProvince'),"rules":[
        {
          required: true,
          trigger: 'blur',
          message: _vm.$t('placeholder.plsInput'),
        } ]}},[_c('el-input',{attrs:{"maxlength":"50","clearable":""},model:{value:(_vm.form.province),callback:function ($$v) {_vm.$set(_vm.form, "province", $$v)},expression:"form.province"}})],1),_c('el-form-item',{attrs:{"prop":"city","label":_vm.$t('cusDetail.City'),"rules":[
        {
          required: true,
          trigger: 'blur',
          message: _vm.$t('placeholder.plsInput'),
        } ]}},[_c('el-input',{attrs:{"maxlength":"50","clearable":""},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})],1),_c('el-form-item',{attrs:{"prop":"street","label":_vm.$t('cusDetail.StreetAddress'),"rules":[
        {
          required: true,
          trigger: 'blur',
          message: _vm.$t('placeholder.plsInput'),
        } ]}},[_c('el-input',{attrs:{"maxlength":"200","clearable":""},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", $$v)},expression:"form.street"}})],1),_c('el-form-item',{attrs:{"prop":"unit","label":_vm.$t('cusDetail.UnitNoAddress')}},[_c('el-input',{attrs:{"maxlength":"50","clearable":""},model:{value:(_vm.form.unit),callback:function ($$v) {_vm.$set(_vm.form, "unit", $$v)},expression:"form.unit"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.FullAddress'),"prop":"address"}},[_vm._v(" "+_vm._s(_vm.addressComputed)+" ")])],1),_c('div',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('cusDetail.Confirm'))+" ")]),_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('cusDetail.Cancel')))])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }