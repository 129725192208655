import { mapGetters } from 'vuex'
// const moduleIds = [
//   200, //产品中心
//   201, //产品详情
//   100, //账号中心
//   0, // 新增询盘
//   1, //询盘管理列表
//   2, // 询盘详情
//   3, // 询价单详情
//   4, // 编辑询盘
//   302, // 邮件配置
//   300, //我的邮件
//   101, // 客户管理
//   103, //客户详情
//   999, //菜单
//   303,
//   102, //公司详情
//   301, //邮件检查
//   400, //销售订单列表
//   401, //销售订单详情
// ]

// const moduleId2LangModule = {
//   200: 'productCenter',
//   201: 'productDetail',
//   100: 'personalCenter',
//   0: 'addInquiry',
//   1: 'inquiryList',
//   2: 'inquiryDetails',
//   3: 'quoteDetails',
//   4: 'editInquiry',
//   302: 'mailConfig',
//   300: 'myMail',
//   101: 'cusManage',
//   103: 'cusDetail',
//   999: 'menu',
//   303: 'boxsetting',
//   102: 'companydetail',
//   301: 'mailcheck',
//   400: 'orderList',
//   401: 'orderDetails',
// }

// const langConfig = {
//   en: {},
//   zh: {},
// }

// const INTERVAL = 1200

// const getLangs = (reqIds, isTheLastQueue) => {
//   if (reqIds.length === 0) {
//     return Promise.resolve([])
//   }
//   const promiseAll = []
//   reqIds.forEach((id) =>
//     promiseAll.push(getInternationalTransInfo(id, isTheLastQueue))
//   )
//   return Promise.all(promiseAll)
// }

// export function initModuleIds() {
//   return moduleIds.slice()
// }

// export default function timeChunkForLang(moduleIds, count = 5) {
//   const token = localStorage.getItem('token')
//   if (!token) return
//   //并发请求限制为5
//   let _resolve, _reject
//   const p = new Promise((resolve, reject) => {
//     _resolve = resolve
//     _reject = reject
//   })
//   count = Math.min(moduleIds.length, count)
//   var timer = setInterval(() => {
//     const reqIds = moduleIds.splice(0, count) //获取分批请求id
//     const hasDone = moduleIds.length === 0 // 判断是否是最后一批
//     getLangs(reqIds, hasDone).then((res) => {
//       if (res.length > 0) {
//         const done = res[0].done
//         res.forEach((item) => {
//           const configKey = moduleId2LangModule[item.moduleId]
//           langConfig['zh'][configKey] = {}
//           langConfig['en'][configKey] = {}
//           const list = item.data
//           list.forEach((subitem) => {
//             const { baseCode, cnName, enName } = subitem
//             langConfig['zh'][configKey][baseCode] = cnName
//             langConfig['en'][configKey][baseCode] = enName
//           })
//         })
//         if (done) {
//           _resolve(langConfig)
//           clearInterval(timer)
//         }
//       }
//     })
//   }, INTERVAL)
//   return p
// }

// export function getLangForRefresh(app) {
//   window.addEventListener('load', () => {
//     if (!getLangConfig(LOCAL_CONFIG_KEY)) {
//       return
//     }
//     timeChunkForLang(initModuleIds()).then((res) => {
//       updateI18nMessages(res, app)
//     })
//   })
// }

const langMixin = {
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },

  watch: {
    lang() {
      if (typeof this.clearValidate === 'function') {
        this.clearValidate()
      }
    },
  },
}

export { langMixin }
