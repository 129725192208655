var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialogFormVisible)?_c('el-dialog',{attrs:{"title":_vm.$t(_vm.title),"visible":_vm.dialogFormVisible,"width":"40%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('cusDetail.Confirm'))+" ")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('cusDetail.Cancel')))])],1)]},proxy:true}],null,false,2742427854)},[_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"100px","label-position":"top"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.EvaluationType'),"prop":"evaluationType"}},[_c('el-radio-group',{model:{value:(_vm.form.evaluationType),callback:function ($$v) {_vm.$set(_vm.form, "evaluationType", $$v)},expression:"form.evaluationType"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v(_vm._s(_vm.$t('cusDetail.Praise')))]),_c('el-radio',{attrs:{"label":1}},[_vm._v(_vm._s(_vm.$t('cusDetail.Complain')))])],1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.EvaluationSources'),"prop":"evaluationSource","rules":[
              {
                required: true,
                trigger: 'blur',
                message: _vm.$t('placeholder.plsInput'),
              } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsInput'),"maxlength":"50"},model:{value:(_vm.form.evaluationSource),callback:function ($$v) {_vm.$set(_vm.form, "evaluationSource", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.evaluationSource"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.Evaluator'),"prop":"evaluator","rules":[
              {
                required: true,
                trigger: 'blur',
                message: _vm.$t('placeholder.plsInput'),
              } ]}},[_c('el-input',{attrs:{"maxlength":"50","placeholder":_vm.$t('placeholder.plsInput')},model:{value:(_vm.form.evaluator),callback:function ($$v) {_vm.$set(_vm.form, "evaluator", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.evaluator"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.EvaluationLevel'),"prop":"evaluationLevel","rules":[
              {
                required: true,
                trigger: 'blur',
                message: _vm.$t('placeholder.plsInput'),
              } ]}},[_c('el-input',{attrs:{"maxlength":"50","placeholder":_vm.$t('placeholder.plsInput')},model:{value:(_vm.form.evaluationLevel),callback:function ($$v) {_vm.$set(_vm.form, "evaluationLevel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.evaluationLevel"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.EvaluationDate'),"prop":"evaluationDate","rules":[
              {
                required: true,
                trigger: 'blur',
                message: _vm.$t('placeholder.plsSel'),
              } ]}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":_vm.$t('placeholder.selDate')},model:{value:(_vm.form.evaluationDate),callback:function ($$v) {_vm.$set(_vm.form, "evaluationDate", $$v)},expression:"form.evaluationDate"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.AssociatedOrder'),"prop":"associatedOrder"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsInput')},model:{value:(_vm.form.associatedOrder),callback:function ($$v) {_vm.$set(_vm.form, "associatedOrder", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.associatedOrder"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.AssociatedCustomerRepresentative'),"rules":{
              required: _vm.form.evaluationType === 1 ? true : false,
              trigger: 'change',
              message: '请选择客户代表',
            },"prop":"associatedSalesman"}},[_c('el-input',{attrs:{"clearable":"","placeholder":_vm.$t('placeholder.plsSel')},on:{"focus":function($event){return _vm.selectUserClick('businessName')},"change":_vm.businessNameChange},model:{value:(_vm.form.associatedSalesman),callback:function ($$v) {_vm.$set(_vm.form, "associatedSalesman", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.associatedSalesman"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.EvaluationContent'),"prop":"evaluationContent","rules":[
              {
                required: true,
                trigger: 'blur',
                message: _vm.$t('placeholder.plsInput'),
              } ]}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 2, maxRows: 4 },"maxlength":"1000","show-word-limit":"","placeholder":_vm.$t('placeholder.plsInput')},model:{value:(_vm.form.evaluationContent),callback:function ($$v) {_vm.$set(_vm.form, "evaluationContent", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.evaluationContent"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusDetail.Image')}},[_c('el-row',{staticClass:"el-row-upload"},[_c('erp-upload-images',{attrs:{"allowedType":_vm.allowedType,"limit":9,"tips-visible":false,"tip-text":_vm.$t('cusDetail.SupportedFormatsTips'),"default-file-list":_vm.defaultFileList4},on:{"change":_vm.changeUploadList4}})],1)],1)],1)],1)],1),_c('UserChoose',{ref:"UserChoose",on:{"choose-user":_vm.chooseUser}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }