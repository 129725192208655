<template>
  <div class="public-container">
    <div v-if="$route.query.noReturn">
      <el-row
        type="flex"
        align="middle"
        style="font-size: 18px; color: #303133; font-family: PingFang Bold"
      >
        <p>
          <!-- <label class="f_s_14">客户：</label> -->
          <label class="f_s_14">{{ $t('cusDetail.CustomerName') }}：</label>
          <span class="f_s_16">{{ customerInfo.customerName }}</span>
        </p>
        <p class="ml20">
          <label class="f_s_14">
            <!-- 编码： -->
            {{ $t('cusDetail.CustomerCode') }}:
          </label>
          <span class="f_s_16">{{ customerInfo.customerCode }}</span>
        </p>
      </el-row>
    </div>
    <el-row v-else>
      <el-page-header @back="goBack" :title="$t('other.back')">
        <template slot="content">
          <el-row type="flex" align="middle">
            <p>
              <label class="f_s_14">
                <!-- 客户： -->
                {{ $t('cusDetail.CustomerName') }}:
              </label>
              <span class="f_s_16">{{ customerInfo.customerName }}</span>
            </p>
            <p class="ml20">
              <!-- <label class="f_s_14">编码：</label> -->
              <label class="f_s_14">{{ $t('cusDetail.CustomerCode') }}:</label>
              <span class="f_s_16">{{ customerInfo.customerCode }}</span>
            </p>
          </el-row>
        </template>
      </el-page-header>
    </el-row>
    <el-row class="mt10 tabs-content">
      <el-tabs
        v-model="activeName"
        type="border-card"
        @tab-click="handleClick"
        :class="{ 'client-mail': activeName == 'fourth' }"
      >
        <!-- <el-tab-pane label="客户信息" name="first">-->
        <el-tab-pane :label="$t('cusDetail.CustomerInformation')" name="first">
          <!-- 编辑 -->
          <ClientManageDetailMsg
            v-if="stateControl.first && editClientBol"
            :isEditParams="isEditParams"
            ref="ClientManageDetailMsg"
          />
          <!-- 详情 -->
          <ClientManageDetailMsg2
            v-if="stateControl.first && !editClientBol"
            :customerId="customerId"
            :isEditParams="isEditParams"
            :isBlacklist="isBlacklist"
            :removeAppraise="removeAppraise"
            ref="ClientManageDetailMsg2"
            @editClient="editClient"
          />
        </el-tab-pane>
        <!-- label="订单记录" -->
        <el-tab-pane
          :label="$t('cusDetail.OrderRecords')"
          name="second"
          v-if="permissionsTab('Order:List')"
        >
          <OrderRecord ref="OrderRecord" v-if="stateControl.second" />
        </el-tab-pane>
        <!--  label="询盘记录" -->
        <el-tab-pane
          :label="$t('cusDetail.InquiryRecords')"
          name="third"
          v-if="permissionsTab('INQUIRY:MANAGE')"
        >
          <EnquiryRecord ref="EnquiryRecord" v-if="stateControl.third" />
        </el-tab-pane>
        <!-- label="邮件记录" -->
        <el-tab-pane :label="$t('cusDetail.MailRecords')" name="fourth">
          <mail-record
            :email="customerEmail"
            :linkmans="linkmans"
            v-if="stateControl.fourth"
          ></mail-record>
        </el-tab-pane>
      </el-tabs>
      <el-row class="right-fixed" v-if="!isBlacklist && !editClientBol">
        <el-button
          type="primary"
          plain
          size="mini"
          @click="removeAdministrant"
          v-if="customerInfo.removeCharge === '1'"
        >
          <!-- 移除分管人 -->
          {{ $t('cusDetail.RemoveAssignee') }}
        </el-button>
        <el-button
          type="primary"
          plain
          size="mini"
          @click="addAppraise"
          v-allowed="['Client:Appraise']"
        >
          <!-- 添加客户评价 -->
          {{ $t('cusDetail.AddCustomerReviews') }}
        </el-button>
        <el-button type="danger" plain size="mini" @click="addBlacklist">
          <!-- 加入黑名单 -->
          {{ $t('cusDetail.AddToBlacklist') }}
        </el-button>
      </el-row>
    </el-row>

    <!-- 添加客户评价 -->
    <ClinetAppraiseModel ref="ClinetAppraiseModel" />
    <!-- 移除分管人 -->
    <RemoveAppraiseModel
      ref="RemoveAppraiseModel"
      @fetch-data="removeAppraiseClick"
    />
  </div>
</template>

<script>
  import ClientManageDetailMsg from './components/client-manage-detail-msg.vue'
  import ClientManageDetailMsg2 from './components/client-manage-detail-msg2.vue'
  import OrderRecord from './components/order-record.vue'
  import EnquiryRecord from './components/enquiry-record'
  import RemoveAppraiseModel from './components/remove-appraise-model'
  import ClinetAppraiseModel from './components/clinet-appraise-model'
  import MailRecord from './components/mail-record'

  import {
    getDetailForEditShow,
    customerBlackList,
  } from '@/api/client-supplier/client-manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ClientManageDetail',

    components: {
      ClientManageDetailMsg,
      ClientManageDetailMsg2,
      OrderRecord,
      EnquiryRecord,
      MailRecord,
      ClinetAppraiseModel,
      RemoveAppraiseModel,
    },
    data() {
      return {
        activeName: 'first',
        isEditParams: false,
        stateControl: {
          first: true,
          second: false,
          third: false,
          fourth: false,
        },
        customerInfo: {},
        customerId: '', //客户id
        customerEmail: '', //客户邮箱
        linkmans: [],
        isBlacklist: false,
        editClientBol: false, //是否是编辑客户
        removeAppraise: false, //移除分管人触发
      }
    },
    computed: {
      ...mapGetters({
        permissions: 'user/permissions',
      }),
    },
    created() {
      if (this.$route.query.customerId) {
        this.customerId = this.$route.query.customerId
        this.getDetailForEditShow()
      }

      if (this.$route.query.operate === 'show') {
        this.editClientBol = false
      } else {
        this.editClientBol = true
      }
    },
    mounted() {},
    methods: {
      editClient() {
        //this.editClientBol = true
      },
      // tabs权限控制
      permissionsTab(tab) {
        return this.permissions.some((item) => item == tab)
      },
      //客户详情
      async getDetailForEditShow() {
        let response = await getDetailForEditShow({ infoId: this.customerId })
        if (response?.code === '000000') {
          this.customerInfo = response.data
          this.customerEmail = this.customerInfo.email || ''
          this.linkmans = this.customerInfo.linkmans || []
          if (this.customerInfo.customerType === 2) {
            this.isBlacklist = true
          }
        }
      },
      handleClick(tab, event) {
        this.stateControl[tab.name] = true
      },

      //添加客户评价
      addAppraise() {
        this.$refs['ClinetAppraiseModel'].showAddEdit(this.customerId)
      },

      //移除分管人
      removeAdministrant() {
        this.$refs['RemoveAppraiseModel'].showAddEdit(
          this.customerId,
          this.customerInfo.email
        )
      },
      //移除分管人触发客户详情
      removeAppraiseClick() {
        this.removeAppraise = true
      },

      //加入黑名单
      addBlacklist() {
        // this.$prompt('是否确定将该客户加入黑名单？', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        //   remarks: '请输入原因',
        //   inputErrorMessage: '请输入原因',
        //   inputValidator: (value) => {
        //     if (!value) {
        //       return '原因不能为空'
        //     }
        //   },
        // })
        const self = this
        this.$prompt(this.$t('reqmsg.$60'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('reqmsg.M2006'),
          cancelButtonText: this.$t('reqmsg.M2007'),
          type: 'warning',
          remarks: this.$t('reqmsg.$61'),
          inputErrorMessage: this.$t('reqmsg.$61'),
          inputValidator: (value) => {
            if (!value) {
              return self.$t('reqmsg.$61')
            }
          },
        }).then(async ({ value }) => {
          let params = {
            infoId: this.customerInfo.infoId,
            remarks: value,
          }
          let response = await customerBlackList(params)
          if (response?.code === '000000') {
            this.$baseMessage(
              // '操作成功',
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success'
            )
            this.goBack()
          }
        })
      },

      //编辑按钮点击
      editClick() {
        this.$router.push({
          path: '/client-supplier/client-manage-detail',
          query: { customerId: this.customerInfo.infoId, operate: 'edit' },
        })
      },
      goBack() {
        this.$router.push('/client-supplier/client-manage/index')
      },
    },
  }
</script>

<style scoped lang="scss">
  .public-container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    .right-fixed {
      position: absolute;
      top: 6.5px;
      right: 50px;
    }
    .tabs-content {
      padding: 0 30px;
      height: calc(100% - 55px);
    }
  }
  ::v-deep {
    .el-tabs--border-card {
      box-shadow: 0 0 0 0 rgb(0, 0, 0);
    }
    .el-tabs {
      height: 100%;
    }
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__content {
      height: calc(100% - 39px);
      overflow-y: auto;
    }
    .client-mail .el-tabs__content {
      padding: 0;
    }
  }
</style>
