<template>
  <el-dialog
    :title="title"
    width="50%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    v-if="dialogVisible"
    :before-close="close"
  >
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column
        type="index"
        label="#"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="collectionNumber"
        label="关联收款单"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span class="link">
            {{ scope.row.collectionNumber }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderNumber"
        label="关联订单"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            class="link"
            @click="purchaseOrderNumberClick(scope.row.orderNumber)"
          >
            {{ scope.row.orderNumber }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="allocatedMoney"
        label="待分配金额"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="salesName"
        label="客户代表"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="操作"
        width="100"
        fixed="right"
        :resizable="false"
      >
        <template slot-scope="scope">
          <!--  v-allowed="['Product:Operate']" -->
          <el-tooltip content="绑定订单" placement="top">
            <el-button
              type="primary"
              plain
              circle
              size="mini"
              @click="bindOrderClick(scope.row)"
            >
              <span class="iconfont icon-bangding"></span>
            </el-button>
          </el-tooltip>
          <!--  v-allowed="['Product:Operate']" -->
          <el-tooltip content="退款" placement="top">
            <el-button
              type="danger"
              plain
              circle
              size="mini"
              @click="refundClick(scope.row)"
            >
              <span class="iconfont icon-tuikuan"></span>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="text-right mt15">
      待分配金额：
      <span>{{ totalAllocatedMoney }}</span>
    </el-row>
    <div class="text-center mt30">
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 绑定订单 -->
    <el-dialog
      title="待分配金额绑定订单"
      :visible.sync="dialogVisible2"
      width="30%"
      :before-close="handleClose2"
      append-to-body
    >
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <el-form-item
          label="销售订单号"
          prop="orderId"
          :rules="[
            {
              required: true,
              message: '请选择销售订单号',
              trigger: 'change',
            },
          ]"
        >
          <el-select
            v-model="form.orderId"
            placeholder="请选择"
            class="w240"
            @change="orderCodeChange"
          >
            <el-option
              v-for="(item, index) in orderCodeData"
              :key="index"
              :label="item.orderNumber"
              :value="item.orderId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="bindOrderConfirmClick">
          确认
        </el-button>
        <el-button @click="dialogVisible2 = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 退款 -->
    <el-dialog
      title="退款"
      :visible.sync="dialogVisible3"
      width="30%"
      :before-close="handleClose3"
      append-to-body
    >
      <el-form
        :model="form2"
        :rules="rules2"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="退款金额">
          <p>￥10.00</p>
        </el-form-item>
        <el-form-item label="退款账户" prop="zhanghu">
          <el-input v-model="form2.zhanghu" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="原因说明" prop="yuanyin">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            placeholder="请输入"
            v-model="form2.yuanyin"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="dialogVisible3 = false">
          确认
        </el-button>
        <el-button @click="dialogVisible3 = false">取 消</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {
  listAllocatedMoney,
  listOrderNumberByCompany,
  allocatedMoneyBindOrder,
} from '@/api/client-supplier/client-manage'
import { paymentStatusVO } from '@/api/finance/finance-payment'
export default {
  name: 'ToBeAssignModel',
  data() {
    return {
      title: '待分配金额',
      dialogVisible: false,
      tableData: [], //待分配金额数据
      dialogVisible2: false,
      form: {
        orderId: '', //订单id
        orderNumber: '', //订单号
        allocatedMoney: '', //待分配金额
        customerAccountBalanceId: '', //账户表业务id
        financeId: '', //财务id
      },
      // rules: {
      //   orderId: [
      //     { required: true, trigger: 'change', message: '请选择销售订单号' },
      //   ],
      // },
      orderCodeData: [], //绑定订单下拉

      form2: {
        zhanghu: '',
        yuanyin: '',
      },
      rules2: {
        zhanghu: [
          { required: true, trigger: 'blur', message: '请输入退款账户' },
        ],
        yuanyin: [{ required: true, trigger: 'blur', message: '请输入原因' }],
      },
      dialogVisible3: false,
      companyName: '', //公司名称
      currentOrderRow: {}, //当前关联收款单信息
      totalAllocatedMoney: 0, //待分配金额
    }
  },

  methods: {
    showAddEdit(infoId, companyName) {
      this.companyName = companyName
      listAllocatedMoney({ customerId: infoId }).then((res) => {
        if (res.code === '000000') {
          this.tableData = res.data

          this.tableData.forEach((item) => {
            this.totalAllocatedMoney += item.allocatedMoney
          })
        }
      })
      this.dialogVisible = true
    },

    //销售订单跳转
    async purchaseOrderNumberClick(code) {
      let response = await paymentStatusVO({
        orderCode: code,
      })
      if (response.code == '000000') {
        let orderStatus = response.data.orderStatus
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: { orderCode: code, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      }
    },

    //绑定订单click
    bindOrderClick(row) {
      this.currentOrderRow = row
      listOrderNumberByCompany({ companyName: this.companyName }).then(
        (res) => {
          if (res.code === '000000') {
            this.orderCodeData = res.data
          }
        }
      )

      this.dialogVisible2 = true
    },
    //绑定订单确认
    bindOrderConfirmClick() {
      this.form.orderNumber = this.orderCodeData.filter((item) => {
        return this.form.orderId === item.orderId
      })[0].orderNumber

      this.form.allocatedMoney = this.currentOrderRow.allocatedMoney
      this.form.customerAccountBalanceId =
        this.currentOrderRow.customerAccountBalanceId
      this.form.financeId = this.currentOrderRow.financeId

      // this.$refs['form'].validate(async (valid) => {
      //   if (valid) {
      //     this.form.orderCode = this.form.orderCodeData.filter(
      //       (item) => this.form.orderId === item.orderId
      //     )
      //   }
      // })
    },
    orderCodeChange() {},

    //关闭绑定订单弹框
    handleClose2() {
      this.dialogVisible2 = false
    },

    //退款click
    refundClick() {
      this.dialogVisible3 = true
    },

    //关闭退款弹框
    handleClose3() {
      this.dialogVisible3 = false
    },

    // 关闭弹窗重置表单
    close() {
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.address {
  .el-form-item {
    margin-bottom: 18px;
  }
}
.link {
  color: #46a6ff;
  cursor: pointer;
  text-decoration: underline;
}
</style>
