<template>
  <!-- title="提交反馈" -->
  <el-dialog
    :title="$t('other.Submitfeedback')"
    :visible.sync="dialogFormVisible"
    width="40%"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        label-position="top"
      >
        <el-row>
          <!-- label="反馈内容" -->
          <el-form-item
            :label="$t('other.Contentofthefeedback')"
            prop="feedbackContent"
            :rules="[
              {
                required: true,
                trigger: 'blur',
                message: $t('placeholder.plsInput'),
              },
            ]"
          >
            <el-input
              type="textarea"
              v-model.trim="form.feedbackContent"
              :autosize="{ minRows: 2, maxRows: 6 }"
              maxlength="1000"
              show-word-limit
              :placeholder="$t('placeholder.plsInput')"
            />
          </el-form-item>
        </el-row>
        <el-row>
          <!-- label="图片" -->
          <el-form-item :label="$t('cusDetail.Image')">
            <!-- tip-text="支持格式：jpg/jpeg/png，最多上传九张，单个文件大小不允许超过2MB" -->
            <el-row class="el-row-upload">
              <erp-upload-images
                :allowedType="allowedType"
                :limit="9"
                :tips-visible="false"
                :tip-text="$t('cusDetail.SupportedFormatsTips')"
                :default-file-list="defaultFileList4"
                @change="changeUploadList4"
              ></erp-upload-images>
            </el-row>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <!-- <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button> -->
        <el-button type="primary" :loading="loading" @click="save">
          {{ $t('cusDetail.Confirm') }}
        </el-button>
        <el-button @click="close">{{ $t('cusDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { updateFeedback } from '@/api/client-supplier/client-manage'
  export default {
    name: 'FeedbackModel',
    data() {
      return {
        loading: false,
        form: {
          evaluationRecordsId: '', //id
          status: 1, //状态(好评不传, 差评传 0, 提交反馈时传 1)：0,待处理 1,已反馈
          feedbackContent: '', //反馈内容
          feedbackPicture: '',
        },
        rules: {
          // feedbackContent: [
          //   { required: true, trigger: 'blur', message: '请输入反馈内容' },
          // ],
        },
        dialogFormVisible: false,
        allowedType: 'jpg、 png、jpeg',
        defaultFileList4: [], //评价照片
        enterprisePhoto: [],
      }
    },
    methods: {
      //新增
      showAddEdit(id) {
        this.form.evaluationRecordsId = id
        this.dialogFormVisible = true
      },

      //关闭
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },

      //反馈照片
      changeUploadList4(list) {
        this.enterprisePhoto = []
        if (list.length) {
          list.forEach((item) => {
            this.enterprisePhoto.push(item.url)
          })
          this.form.feedbackPicture = this.enterprisePhoto.join(',')
        } else {
          this.form.feedbackPicture = ''
        }
      },

      //保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let form = this.utils.deepCopy(this.form)
            this.loading = true
            let response = await updateFeedback(form)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                // '操作成功',
                this.$t('reqmsg.$7'),
                'success',
                false,
                'erp-hey-message-success'
              )
            }
            this.$emit('feed-back')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
