<template>
  <el-row class="audit-progress" v-if="activities.length">
    <el-timeline class="el_timeline">
      <el-timeline-item
        v-for="(item, index) in activities"
        :key="index"
        placement="top"
        :timestamp="item.auditStep"
        color="#46a6ff"
      >
        <el-row class="floor">
          <el-row type="flex" align="middle">
            <el-row class="f_s_15 ml10">
              <p style="word-break: break-all; line-height: 18px">
                {{ getName(item,'managerName') }}
              </p>
            </el-row>
            <el-tag class="ml15" :type="item.eventType | statusFilter">
              {{ $t(customerManageEnum(item.eventType)) }}
            </el-tag>
          </el-row>
          <el-row class="mt10 f_s_12 ml10" type="flex" align="middle">
            <p>
              <!-- <label>操作人：</label> -->
              <label>{{ $t('other.Operator') }}:</label>
              <!-- <span>{{ item.createName }}</span> -->
              <span>{{ getName(item, 'createName') }}</span>
            </p>
            <p class="ml20">
              <!-- <label>操作时间：</label> -->
              <label>{{ $t('other.Operationtime') }}:</label>
              <span>{{ item.operationTime }}</span>
            </p>
          </el-row>
        </el-row>
      </el-timeline-item>
    </el-timeline>
  </el-row>
</template>

<script>
import {
  customerManagerHistoryForCharge,
  customerManagerHistoryForOwner,
} from '@/api/client-supplier/client-manage'
import { customerManageEnumLang } from '@/utils/en-match-zh'
import nameMixin from '@/utils/name-mixin'
export default {
  name: 'LeadingHistoryDrawer',
  mixins: [nameMixin],
  props: {
    infoId: { type: String, default: '' },
    historyStr: { type: String, default: '' },
  },
  data() {
    return {
      drawerVisible: false,
      activities: [],
    }
  },
  watch: {
    historyStr: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.infoId) return
        this.activities = []
        if (value == 'leading') {
          this.getCustomerManagerHistoryForOwner(this.infoId)
        } else {
          this.getCustomerManagerHistoryForCharge(this.infoId)
        }
      },
    },
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        600001: 'info',
        600002: 'success',
        600003: 'warning',
        600004: 'warning',
        600005: 'info',
        600006: 'info',
      }
      return statusMap[status]
    },
  },
  created() {},
  methods: {
    //客户管理历史记录-分管人
    async getCustomerManagerHistoryForCharge(value) {
      let response = await customerManagerHistoryForCharge({ infoId: value })
      if (response.code === '000000') {
        this.activities = response.data
      }
    },
    //客户管理历史记录-负责人
    async getCustomerManagerHistoryForOwner(value) {
      let response = await customerManagerHistoryForOwner({ infoId: value })
      if (response.code === '000000') {
        this.activities = response.data
      }
    },
    showDraw(str) {
      this.drawerVisible = true
    },

    //客户历史记录状态匹配
    customerManageEnum(val) {
      return customerManageEnumLang(val)
    },
  },
}
</script>

<style lang="scss" scoped>
.audit-progress {
  .el_timeline {
    .floor {
      .floor-content-row + .floor-content-row {
        margin-left: 20px;
      }
    }
  }
}

.el_timeline {
  width: 90%;
  margin: 0 auto;
}
::v-deep {
  .el_timeline {
    .el-timeline-item__wrapper {
      .el-timeline-item__timestamp.is-top {
        font-size: 14px;
        color: #000 !important;
      }
    }
    .el-timeline-item__node--normal {
      left: -3px;
      width: 15px;
      height: 16px;
    }
  }
}
</style>
