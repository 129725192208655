<template>
  <div class="mail-record">
    <div class="tool-bar">
      <el-form inline>
        <el-form-item>
          <!-- placeholder="邮件类型" -->
          <el-select
            :placeholder="$t('placeholder.mailType')"
            v-model="form.boxType"
            @change="searchFilter"
          >
            <!-- label="全部" -->
            <el-option
              :label="$t('selOpt.all')"
              :value="null"
              selected
            ></el-option>
            <!-- label="收件" -->
            <el-option :label="$t('selOpt.receive')" :value="0"></el-option>
            <!-- label="发件" -->
            <el-option :label="$t('selOpt.send')" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <!-- placeholder="邮件状态" -->
          <el-select
            v-model="form.reply"
            @change="searchFilter"
            :placeholder="$t('placeholder.mailStatus')"
          >
            <!-- label="全部" -->
            <el-option
              :value="null"
              selected
              :label="$t('selOpt.all')"
            ></el-option>
            <!-- label="已回复" -->
            <el-option :value="1" :label="$t('selOpt.replied')"></el-option>
            <!-- label="未回复" -->
            <el-option :value="0" :label="$t('selOpt.unanswered')"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <!-- placeholder="附件" -->
          <el-select
            :placeholder="$t('placeholder.appendix')"
            v-model="form.eliminate"
            @change="searchFilter"
          >
            <!-- label="全部" -->
            <el-option
              :value="null"
              selected
              :label="$t('selOpt.all')"
            ></el-option>
            <!-- label="有附件" -->
            <el-option
              :value="1"
              :label="$t('selOpt.withAttachments')"
            ></el-option>
            <!-- label="无附件" -->
            <el-option
              :value="0"
              :label="$t('selOpt.noAttachment')"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <!-- placeholder="关联客户代表" -->
          <el-input
            :placeholder="$t('placeholder.affiliateAccountRepresentative')"
            v-model="form.businessName"
            @focus="businessChange"
            clearable
            @clear="clearMan"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <!-- placeholder="请输入搜索内容" -->
          <el-input
            v-model="form.keyWord"
            :placeholder="$t('placeholder.inputSearchcontent')"
            style="width: 280px"
            size="small"
            clearable
            @clear="searchFilter"
            @keydown.native.enter="searchFilter"
          >
            <!-- placeholder="全部" -->
            <el-select
              v-model="form.keyWordSearchType"
              slot="prepend"
              :placeholder="$t('selOpt.all')"
            >
              <!-- <el-option label="发件人" :value="1"></el-option>
              <el-option label="收件人" :value="2"></el-option>
              <el-option label="主题" :value="5"></el-option>
              <el-option label="内容" :value="6"></el-option> -->
              <!-- <el-option :label="$t('selOpt.sender')" :value="1"></el-option>
              <el-option
                :label="$t('selOpt.withAttachments')"
                :value="2"
              ></el-option>
              <el-option :label="$t('selOpt.topic')" :value="5"></el-option>
              <el-option :label="$t('selOpt.content')" :value="6"></el-option> -->
              <el-option :label="$t('other.all')" :value="0"></el-option>
              <el-option :label="$t('selOpt.sender')" :value="1"></el-option>
              <el-option :label="$t('selOpt.recipient')" :value="2"></el-option>
              <el-option :label="$t('selOpt.topic')" :value="5"></el-option>
              <el-option :label="$t('selOpt.content')" :value="6"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button plain type="primary" class="ml10" @click="searchFilter">
            <!-- 搜索 -->
            {{ $t('cusDetail.Search') }}
          </el-button>
          <el-button plain class="ml10" @click="clearSearch">
            <!-- 重置  -->
            {{ $t('cusDetail.Reset') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="mail-content-wrapper">
      <div class="aside">
        <div class="mail-list">
          <div
            class="item"
            v-for="item in mailList"
            :key="item.mail"
            :class="
              currentMail == item.mailId ? 'search-item active' : 'search-item'
            "
            @click="getDetail(item)"
          >
            <div class="item-head">
              <mark class="mark receive" v-if="getMailType(item.boxName) != 1">
                <!-- 收件 -->
                {{ $t('selOpt.receive') }}
              </mark>
              <mark class="mark send" v-if="getMailType(item.boxName) == 1">
                <!-- 发件 -->
                {{ $t('selOpt.send') }}
              </mark>
              <span class="name">
                {{ item.nodeName }}
                <el-tooltip
                  effect="dark"
                  :content="item.emailMemo"
                  placement="top"
                >
                  <i
                    v-show="item.emailMemo"
                    class="remark iconfont icon-qizhi"
                  ></i>
                </el-tooltip>
              </span>
              <time class="time">
                {{ utils.formatTime(item.sendTime, 'mail') }}
              </time>
            </div>
            <div class="item-body">
              <div class="dt">
                <template v-if="item.boxType === 0">
                  <!-- 未读 -->
                  <!-- content="未读邮件" -->
                  <el-tooltip
                    placement="top"
                    v-if="item.readFlag !== 1"
                    :content="$t('iconbtn.unreadmail')"
                  >
                    <i class="iconfont icon-youxiang"></i>
                  </el-tooltip>
                  <!-- 已发邮件 -->
                  <!-- content="已发邮件" -->
                  <el-tooltip
                    :content="$t('iconbtn.sentmail')"
                    placement="top"
                    v-if="item.issued == 1"
                  >
                    <i class="iconfont icon-qingqiuyifasong send"></i>
                  </el-tooltip>
                  <!-- 回复and转发 -->
                  <!-- content="已回复转发邮件" -->
                  <el-tooltip
                    :content="$t('iconbtn.forwardedmessagereplied')"
                    placement="top"
                    v-else-if="item.reply == 1 && item.isForward == 1"
                  >
                    <i class="iconfont icon--huxiangguanzhu replyed"></i>
                  </el-tooltip>
                  <!-- 已转发 -->
                  <!-- content="已转发邮件" -->
                  <el-tooltip
                    placement="top"
                    v-else-if="item.isForward == 1"
                    :content="$t('iconbtn.messageforwarded')"
                  >
                    <i class="iconfont icon-zhuanfa1 replyed"></i>
                  </el-tooltip>
                  <!-- 已回复 -->
                  <!-- content="已回复邮件" -->
                  <el-tooltip
                    :content="$t('iconbtn.messagereplied')"
                    placement="top"
                    v-else-if="item.reply == 1"
                  >
                    <i class="iconfont icon-huifu replyed"></i>
                  </el-tooltip>
                  <!-- content="已读邮件" -->
                  <el-tooltip
                    :content="$t('iconbtn.readmail')"
                    placement="top"
                    v-else-if="item.readFlag == 1"
                  >
                    <i class="iconfont icon-youxiang2 looked"></i>
                  </el-tooltip>
                </template>
              </div>
              <div class="dd">
                <p class="summary">
                  <i
                    v-if="item.enclosure == 1"
                    class="iconfont icon-biezhen file"
                  ></i>
                  <!-- <i class="iconfont icon-gantanhao warning"></i> -->
                  <span v-if="item.title">
                    {{ item.title | lengthLimit(70) }}
                  </span>
                  <span v-else>--</span>
                </p>
                <p class="desc" v-if="item.content">
                  {{
                    (item.content
                      ? utils.removeTAG(item.content) || '--'
                      : '--') | lengthLimit(80)
                  }}
                </p>
                <p class="desc" v-else>--</p>
                <ul
                  class="label-list"
                  v-if="item.labelList && item.labelList.length"
                >
                  <li
                    v-for="label in item.labelList"
                    :key="label.mailLabelConfigId"
                    :style="{ 'background-color': label.labelColor }"
                  >
                    <span class="label">{{ label.labelName }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="no-data" v-if="mailList.length == 0">
            <!-- 暂无数据 -->
            {{ $t('other.noData') }}
          </div>
        </div>

        <div class="search-pagination">
          <el-select
            class="page-size"
            v-model="page.pageSize"
            @change="changePageSize"
            size="mini"
          >
            <!-- <el-option :value="10" label="10条/页"></el-option>
            <el-option :value="20" label="20条/页"></el-option>
            <el-option :value="50" label="50条/页"></el-option>
            <el-option :value="100" label="100条/页"></el-option>
            <el-option :value="200" label="200条/页"></el-option> -->
            <el-option :value="10" :label="$t('selOpt.$10')"></el-option>
            <el-option :value="20" :label="$t('selOpt.$20')"></el-option>
            <el-option :value="50" :label="$t('selOpt.$50')"></el-option>
            <el-option :value="100" :label="$t('selOpt.$100')"></el-option>
            <el-option :value="200" :label="$t('selOpt.$200')"></el-option>
          </el-select>
          <div class="page-current">
            <el-button
              type="text"
              icon="el-icon-arrow-left"
              @click="beforePage"
              :disabled="page.pageNo < 2"
            >
              <!-- 上一页 -->
            </el-button>
            <span class="page-num">
              {{ page.total ? page.pageNo : 0 }} / {{ page.pages }}
            </span>
            <el-button
              type="text"
              icon="el-icon-arrow-right"
              @click="nextPage"
              :disabled="page.pageNo >= page.pages"
            >
              <!-- 下一页 -->
            </el-button>
          </div>
          <div class="jump">
            <!-- <span>前往</span> -->
            <span>{{ $t('other.goto') }}</span>
            <el-input
              v-model="page.goPage"
              size="mini"
              @blur="goPage"
              :disabled="page.total == 1"
            ></el-input>
            <!-- <span>页</span> -->
            <span>{{ $t('other.Page') }}</span>
          </div>
        </div>
      </div>
      <div class="mail-info-wrapper">
        <div class="mail-detail" v-if="detail">
          <div class="mail-detail-base">
            <div class="title-box">
              <div class="mail-main-title">
                <span class="title">{{ detail.baseInfo.title }}</span>
                <span class="order-num" v-if="detail.baseInfo.businessJson">
                  <!-- 询盘号 -->
                  {{ $t('other.inquiryNumber') }}
                  {{ detail.baseInfo.businessJson }}
                </span>
                <div
                  class="label-item"
                  v-for="label in detail.mailLabelConfigVOS"
                  :key="label.mailLabelConfigId"
                  :style="{ 'background-color': label.labelColor }"
                >
                  <span class="label">{{ label.labelName }}</span>
                </div>
              </div>
            </div>
            <div class="info-wrapper">
              <div class="info-main" v-if="infoDetailed">
                <dl class="base-item">
                  <!-- <dt>发件人</dt> -->
                  <dt>{{ $t('selOpt.sender') }}</dt>
                  <dd>
                    <span class="weight">
                      {{ detail.baseInfo.sendEmail.split('@')[0] }}
                    </span>
                    <span v-text="`<${detail.baseInfo.sendEmail}>`"></span>
                  </dd>
                </dl>
                <dl class="base-item">
                  <!-- <dt>时间</dt> -->
                  <dt>{{ $t('other.date') }}</dt>
                  <dd>
                    <span class="weight">
                      {{
                        detail.baseInfo.timingSwitch
                          ? utils.formatTime(
                              detail.baseInfo.timingSendTime,
                              'mail-info'
                            )
                          : utils.formatTime(
                              detail.baseInfo.sendTime,
                              'mail-info'
                            )
                      }}
                    </span>
                  </dd>
                </dl>
                <dl
                  class="base-item"
                  v-if="detail.extendInfo && detail.extendInfo.receiveEmails"
                >
                  <!-- <dt>收件人</dt> -->
                  <dt>{{ $t('selOpt.recipient') }}</dt>
                  <dd>
                    <span
                      v-for="(
                        v, index
                      ) in detail.extendInfo.receiveEmails.split(',')"
                      :key="index"
                    >
                      <span class="weight">{{ v.split('@')[0] }}</span>
                      <span v-text="`<${v}>;`"></span>
                    </span>
                  </dd>
                </dl>
                <dl
                  class="base-item"
                  v-if="detail.extendInfo && detail.extendInfo.ccEamils"
                >
                  <!-- <dt>抄送人</dt> -->
                  <dt>{{ $t('other.CCperson') }}</dt>
                  <dd>
                    <span
                      v-for="(v, index) in detail.extendInfo.ccEamils.split(
                        ','
                      )"
                      :key="index"
                    >
                      <span class="weight">{{ v.split('@')[0] }}</span>
                      <span v-text="`<${v}>;`"></span>
                    </span>
                  </dd>
                </dl>
                <dl
                  class="base-item"
                  v-if="detail.extendInfo && detail.extendInfo.bccEmails"
                >
                  <!-- <dt>密送人</dt> -->
                  <dt>{{ $t('other.CC') }}</dt>
                  <dd>
                    <span
                      v-for="(v, index) in detail.extendInfo.bccEmails.split(
                        ','
                      )"
                      :key="index"
                    >
                      <span class="weight">{{ v.split('@')[0] }}</span>
                      <span v-text="`<${v}>;`"></span>
                    </span>
                  </dd>
                </dl>
              </div>
              <div class="info-main" v-else>
                <div class="info-simple">
                  {{ detail.baseInfo.sendEmail.split('@')[0] }}
                  {{ '&lt;' + detail.baseInfo.sendEmail + '&gt;' }}
                  <span class="desc">
                    <!-- 于 -->
                    {{ $t('other.At') }}
                    {{
                      detail.baseInfo.timingSwitch
                        ? utils.formatTime(
                            detail.baseInfo.timingSendTime,
                            'mail-info'
                          )
                        : utils.formatTime(
                            detail.baseInfo.sendTime,
                            'mail-info'
                          )
                    }}
                    <!-- 发送给 -->
                    {{ $t('other.sendto') }}
                  </span>
                  <template v-if="detail.extendInfo.receiveEmails">
                    <span
                      v-for="(
                        v, index
                      ) in detail.extendInfo.receiveEmails.split(',')"
                      :key="index"
                    >
                      <span class="weight">{{ v.split('@')[0] }}</span>
                      <span v-text="`<${v}>;`"></span>
                    </span>
                  </template>
                </div>
              </div>
              <span class="btn-key" @click="infoDetailed = !infoDetailed">
                <!-- {{ infoDetailed ? '精简信息>>' : '详细信息>>' }} -->
                {{
                  infoDetailed
                    ? $t('other.Condensedinformation')
                    : $t('other.details')
                }}
              </span>
            </div>
            <div class="myremark" v-if="remarkFlag">
              <div class="remark-text" v-if="!remarkEditFlag">
                <span class="weight">{{ detail.baseInfo.emailMemo }}</span>
              </div>
            </div>
          </div>
          <iframe class="mail-frame" style="border: none"></iframe>
          <div class="mail-deatil-bottom">
            <div
              class="mail-detail-attach"
              v-if="detail.enclosureList && detail.enclosureList.length"
            >
              <div class="attach-title">
                <i class="iconfont icon-biezhen"></i>
                <!-- <span>{{ `附件(${detail.enclosureList.length})` }}</span> -->
                <span>
                  {{
                    `${$t('placeholder.appendix')}(${detail.enclosureList.length})`
                  }}
                </span>
              </div>
              <div
                class="attach-list"
                v-if="detail.enclosureList && detail.enclosureList.length"
              >
                <div
                  v-for="item in detail.enclosureList"
                  :key="item.fileId"
                  @click="handleDownload([item])"
                >
                  <i class="iconfont icon-biezhen"></i>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.enclosureName"
                    placement="top"
                  >
                    <span class="title">{{ item.enclosureName }}</span>
                  </el-tooltip>
                  <router-link
                    class="preview"
                    :to="`/preview-file/index?name=${item.enclosureName}&url=${item.enclosureUrl}`"
                    target="_blank"
                    @click.native.stop
                  >
                    <!-- 预览 -->
                    {{ $t('other.preview') }}
                  </router-link>
                  <span class="size">
                    {{ item.enclosureSize | sizeFilter }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-mail" v-else>
          <div>
            <img src="../../../../assets/mail_images/nomail.png" alt="" />
            <!-- <p>未选择邮件</p> -->
            <p>{{ $t('other.Mailnotselected') }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>
<script>
import { getDetailForEmail } from '@/api/mail/contacts.js'
import { getMailDetailById } from '@/api/mail/mail.js'
import { mailCheckerList, getMemberMailList } from '@/api/mail/inspection.js'
import { mapState, mapGetters } from 'vuex'
import { BusEmit } from '@/utils/eventBus'
import UserChoose from '@/components/userChoose2'
import { getEmailName } from '@/views/mail/my-mail/utils/mail-data'
export default {
  components: {
    UserChoose,
  },
  data() {
    return {
      form: {
        boxType: '', //0收件箱,1发件箱,2草稿箱,3垃圾箱
        catagoryId: '',
        keyWordSearchType: 0,
        reply: '', //:0未回复,1已回复
        keyWord: '',
        businessId: '',
        businessName: '',
        eliminate: '',
      },
      searchType: '', //
      keyword: '',
      page: {
        pageNo: 1,
        pageSize: 10,
        goPage: 1,
        total: 0,
        pages: 0,
      },
      mailList: [],
      infoDetailed: false, //是否显示详细信息
      addContactVisible: false,
      addContactConfig: null,
      quickReplyInfo: '',
      remarkEditFlag: false,
      remarkFlag: false,
      myRemark: '',
      customerId: '',
      currentMail: '',
      detail: null,
    }
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    linkmans: {
      type: Array,
      default() {
        return []
      },
    },
  },
  filters: {
    lengthLimit(val, num) {
      if (val.length > num) {
        return val.substring(0, num) + '...'
      } else {
        return val
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  watch: {
    // linkmans: {
    //   handler(val) {
    //     this.form.keyWord = val
    //       .map((item) => {
    //         return item.email
    //       })
    //       .join(',')
    //     this.getPageList()
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  created() {
    this.customerId = this.$route.query.customerId
    // this.getPageList()
  },
  mounted() {
    if (this.email && this.linkmans.length) {
      this.getPageList()
    }
  },
  methods: {
    getMailType(val) {
      return getEmailName(val) ? getEmailName(val).type : 0
    },
    getPageList() {
      let params = {
        ...this.form,
        ...this.page,
        customerEmail: this.email,
        tenantId: this.userInfo.tenantId,
      }
      switch (this.searchType) {
        case 1:
          params['sendEmail'] = this.keyword
          break
        case 2:
          params['receiveEmails'] = this.keyword
          break
        case 5:
          params['title'] = this.keyword
          break
        case 6:
          params['content'] = this.keyword
          break
        default:
          break
      }
      getMemberMailList(params).then((res) => {
        if (res.code == '000000') {
          this.mailList = res.data.records || []
          this.page.total = res.data.total || 0
          this.page.pages = res.data.pages || 0
          // 默认显示第一条邮件内容
          if (this.mailList.length) {
            this.getDetail(this.mailList[0])
          } else {
            this.detail = null
          }
          // 标签转换
          this.mailList.forEach((item) => {
            if (item.label) {
              item['labelList'] = JSON.parse(item.label)
            } else {
              item['labelList'] = []
            }
            return item
          })
        }
      })
    },
    // 搜索
    searchFilter() {
      this.page.pageNo = 1
      this.page.goPage = this.page.pageNo
      this.getPageList()
    },
    // 重置
    clearSearch() {
      this.form = {
        boxType: '', //0收件箱,1发件箱,2草稿箱,3垃圾箱
        catagoryId: '',
        keyWordSearchType: 0,
        reply: '', //:0未回复,1已回复
        keyWord: this.form.keyWord,
        businessId: '',
        businessName: '',
        eliminate: '',
      }
      this.searchType = '' //
      this.keyword = ''
      this.page = {
        pageNo: 1,
        pageSize: 10,
        goPage: 1,
        total: 0,
      }
      this.getPageList()
    },
    //获取详情
    getDetail(mail) {
      this.currentMail = mail.mailId
      getMailDetailById(mail.mailId).then((res) => {
        if (res.code === '000000') {
          let data = res.data
          this.remarkEditFlag = false
          this.remarkFlag = false
          this.myRemark = ''
          this.detail = data

          setTimeout(() => {
            const iframe = document.querySelector('.mail-frame')
            const iframedoc =
              iframe.contentDocument || iframe.contentWindow.document
            iframedoc.children[0].innerHTML = this.detail.extendInfo.content
          }, 100)

          this.infoDetailed = false
          if (data.baseInfo.emailMemo && data.baseInfo.emailMemo.trim()) {
            this.remarkFlag = true
          }
        }
      })
    },
    //上一页
    beforePage() {
      if (this.page.pageNo > 1) {
        this.page.pageNo--
        this.page.goPage = this.page.pageNo
      }
      this.getPageList()
    },

    //切换页码
    changePageSize(val) {
      this.page.pageSize = val
      this.page.pageNo = 1
      this.page.goPage = this.page.pageNo
      this.getPageList()
    },

    //下一页
    nextPage() {
      if (this.page.pageNo < this.page.total) {
        this.page.pageNo++
        this.page.goPage = this.page.pageNo
      }
      this.getPageList()
    },

    goPage() {
      if (this.page.goPage > this.page.total) {
        this.page.goPage = this.page.total
      }
      this.page.pageNo = this.page.goPage
      this.getPageList()
    },
    // 选择客户代表
    businessChange() {
      this.$refs['UserChoose'].showAddEdit('1', this.form.businessId, true)
    },
    chooseUser(userIdArr) {
      if (userIdArr && userIdArr.length) {
        this.form.businessId = userIdArr[0].id
        this.form.businessName =
          userIdArr[0][this.lang === 'en' ? 'englishName' : 'name']
      }
      this.searchFilter()
    },
    clearMan() {
      this.form.businessId = ''
      this.form.businessName = ''
      this.searchFilter()
    },
    download(url, filename) {
      /**
       * 获取 blob
       * @param  {String} url 目标文件地址
       * @return {cb}
       */

      function getBlob(url, cb) {
        var xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = function () {
          if (xhr.status === 200) {
            cb(xhr.response)
          }
        }
        xhr.send()
      }

      /**
       * 保存
       * @param  {Blob} blob
       * @param  {String} filename 想要保存的文件名称
       */

      function saveAs(blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename)
        } else {
          var link = document.createElement('a')
          var body = document.querySelector('body')
          link.href = window.URL.createObjectURL(blob)
          link.download = filename
          // fix Firefox
          link.style.display = 'none'
          body.appendChild(link)
          link.click()
          body.removeChild(link)
          window.URL.revokeObjectURL(link.href)
        }
      }

      getBlob(url, function (blob) {
        saveAs(blob, filename)
      })
    },

    // 下载文件
    handleDownload(fileArr) {
      fileArr.forEach((item, index) => {
        this.download(
          'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/' + item.enclosureUrl,
          item.enclosureName
        )
      })
    },
  },
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
::v-deep {
  .el-input-group__prepend {
    background-color: #fff;
    .el-select {
      width: 80px;
      .el-input__inner {
        padding-left: 5px;
      }
    }
  }
}
.mail-record {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 177px);
  .tool-bar {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 15px 0;
    border-bottom: 1px solid #f5f7fa;
    > .el-select {
      margin-right: 10px;
    }
  }

  .mail-content-wrapper {
    flex: 1;
    height: 500px;
    display: flex;
    .aside {
      width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #f5f7fa;
    }
    .mail-info-wrapper {
      flex: 1;
    }
  }
}
.mail-list {
  height: 500px;
  flex: 1;
  overflow-y: auto;
  .no-data {
    text-align: center;
    color: #999;
    line-height: 40px;
  }
  .item {
    border-bottom: 1px solid #f5f7fa;
    padding: 6px 4px;
    background-color: #fff;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: #f8f8f8;
    }
  }
  .item-head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .mark {
      font-size: 12px;
      color: #fff;
      line-height: 16px;
      padding: 1px 3px;
      text-align: center;
      border-radius: 2px;
      margin-right: 10px;
      &.receive {
        background-color: #71aaff;
      }
      &.send {
        background-color: #facd91;
      }
    }
    .name {
      font-size: 14px;
      color: #333;
      line-height: 22px;
      flex: 1;
      .icon-qizhi {
        color: #0486fe;
        margin-left: 5px;
      }
    }
    .time {
      font-size: 12px;
      color: #999;
      line-height: 22px;
    }
  }
  .item-body {
    display: flex;
    .dt {
      min-width: 30px;
      margin-right: 10px;
      text-align: right;
      i {
        color: #f7b500;
        &.mail-icon {
          display: block;
        }
        &.looked {
          color: #d9d9d9;
        }
        &.replyed {
          color: #2eb797;
        }
        &.send {
          color: #3abc38;
        }
      }
    }
    .dd {
      flex: 1;
      width: 200px;
      .file {
        color: #fa6400;
      }
    }
    .summary {
      font-size: 14px;
      color: #333;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .desc {
      font-size: 14px;
      color: #999;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .label-list {
      overflow: hidden;
      li {
        float: left;
        margin-right: 10px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 12px;
        color: #fff;
        padding: 0 4px;
        border-radius: 2px;
        max-width: 100%;
        .label {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .remove-icon {
          margin-left: 4px;
          cursor: pointer;
          display: none;
        }
        &:hover {
          .remove-icon {
            display: block;
          }
        }
      }
    }
  }
}

.search-pagination {
  height: 40px;
  background: #fff;
  border-top: 1px solid #f5f7fa;
  display: flex;
  align-items: center;
  padding: 0 20px;
  ::v-deep .el-select {
    width: 60px;
    margin-left: 2px;
    .el-input__inner {
      padding: 0 4px;
    }
    .el-input__suffix {
      display: none;
    }
  }
  .page-current {
    display: flex;
    width: 60px;
    flex: 1;
    align-items: center;
    justify-content: center;
    .page-num {
      padding: 0 10px;
    }
  }
  .jump {
    ::v-deep .el-input {
      width: 32px;
      margin-left: 2px;
      margin-right: 2px;
      .el-input__inner {
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
      }
    }
  }
}
.mail-detail {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  //border: 1px solid red;
  overflow: auto;
  .mail-detail-base {
    border-bottom: 1px solid #f5f7fa;
    padding: 20px;
    box-sizing: border-box;
    .title-box {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      margin-bottom: 10px;
      .mail-main-title {
        // display: flex;
        // align-items: flex-start;
        // flex: 1;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #303030;
        max-width: 150em;
        word-break: break-all;
      }
      .order-num {
        padding: 0 3px;
        white-space: nowrap;
        color: #1890ff;
        background: #e8f4ff;
        font-size: 14px;
        margin-left: 10px;
        .icon-close {
          color: #ccc;
        }
      }
      .label-item {
        display: inline-block;
        margin-left: 10px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        padding: 0 4px;
        border-radius: 2px;
        max-width: 100%;
        line-height: 20px;
        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          vertical-align: middle;
        }
        .remove-icon {
          margin-left: 4px;
          cursor: pointer;
          display: none;
          vertical-align: middle;
        }
        &:hover {
          .remove-icon {
            display: inline-block;
          }
        }
      }
    }
    .operate-btns {
      margin-left: 20px;
      .el-button {
        width: 28px;
        height: 28px;
        padding: 4px;
        font-size: 18px;
      }
    }
    .info-wrapper {
      display: flex;
      .info-main {
        flex: 1;
        width: 200px;
      }
      .info-simple {
        font-size: 14px;
        color: #333;
        line-height: 24px;
        word-break: break-all;
        .desc {
          color: #999;
        }
      }
      .btn-key {
        font-size: 14px;
        color: #999;
        line-height: 24px;
        cursor: pointer;
        &:hover {
          color: #1890ff;
        }
      }
    }
    .base-item {
      display: flex;
      margin-bottom: 10px;
      line-height: 24px;
      dt {
        width: 82px;
        height: 24px;
        background: #f8fbff;
        border-radius: 3px;
        text-align: center;
        line-height: 24px;
        margin-right: 10px;
      }
      dd {
        flex: 1;
        width: 0;
        word-wrap: break-word;
        font-size: 12px;
        color: #777;
        .weight {
          color: #303030;
        }
        .star {
          color: #fa6400;
        }
      }
    }
    .myremark {
      background-color: #f8f8f8;
      padding: 4px 6px;
      margin-top: 6px;
      .remark-text {
        display: flex;
        align-items: flex-end;
        .weight {
          font-size: 14px;
          line-height: 28px;
          word-wrap: break-word;
          flex: 1;
          width: 200px;
        }
      }
      .remark-form {
        display: flex;
        align-items: center;
        .input {
          flex: 1;
          margin-right: 10px;
        }
      }
    }
  }
  .mail-frame {
    flex: 1;
    height: 500px;
  }
  .mail-detail-attach {
    padding: 20px;
    box-sizing: border-box;
    .attach-title {
      margin-bottom: 10px;
      i {
        color: #fa6400;
        font-size: 12px;
      }
      & > span {
        font-size: 12px;
        font-weight: bold;
        color: #303030;
        margin: 0 10px;
      }
    }
    .attach-list {
      display: flex;
      flex-wrap: wrap;
      & > div {
        padding: 0 12px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #f5f7fa;
        font-size: 12px;
        margin-right: 10px;
        line-height: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 200px;
        display: flex;
        i {
          color: #fa6400;
          font-size: 12px;
        }
        .title {
          flex: 1;
          width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #303030;
          line-height: 30px;
          margin-left: 10px;
        }
        .preview {
          color: #1890ff;
          margin-left: 10px;
        }
        .size {
          color: #777;
          margin-left: 10px;
        }
      }
    }
  }
  .mail-deatil-bottom {
    overflow-y: auto;
    max-height: 120px;
  }
}
.no-mail {
  width: 100%;
  height: 100%;
  background: #fff;
  border-left: 1px solid #f5f7fa;
  box-sizing: border-box;
  position: relative;
  & > div {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100px;
      height: 100px;
    }
    p {
      margin-top: 33px;
      color: #777;
      font-size: 14px;
    }
  }
}
</style>