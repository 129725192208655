/**
 * 员工职位
 */
export function matchPosition(val) {
  switch (val) {
    case 0:
      val = '员工'
      break
    case 1:
      val = '部门主管'
      break
    default:
      val = ''
  }
  return val
}

/**
 * 员工状态
 */
export function matchStaffStatus(val) {
  switch (val) {
    case 1:
      val = '在职'
      break
    case 2:
      val = '离职'
      break
    default:
      val = ''
  }
  return val
}

/**
 *  产品-审核状态
 */
export function auditStatus(val) {
  switch (val) {
    case 0:
      val = '录入中'
      break
    case 1:
      val = '审核中'
      break
    case 2:
      val = '审核通过'
      break
    case 3:
      val = '审核驳回'
      break
    case 4:
      val = '已撤销'
      break
    case 5:
      val = '无需审核'
      break
    case 6:
      val = '已删除'
      break
    default:
      val = ''
  }
  return val
}
/**
 *  供应商-审核状态
 */
export function supplierAuditStatus(val) {
  switch (val) {
    case 0:
      val = '提交'
      break
    case 1:
      val = '审核中'
      break
    case 2:
      val = '审核通过'
      break
    case 3:
      val = '审核驳回'
      break
    case 4:
      val = '已撤销'
      break
    case 5:
      val = '录入中'
      break
    case 6:
      val = '无需审核'
      break
    case 7:
      val = '黑名单'
      break
    default:
      val = ''
  }
  return val
}
/**
 *  供应商-审核状态 中英文切换
 */
export function supplierAuditStatusTranlate(val) {
  switch (val) {
    case 0:
      // val = '提交'
      val = 'productCenter.Submit'
      break
    case 1:
      // val = '审核中'
      val = 'productCenter.InReview'
      break
    case 2:
      // val = '审核通过'
      val = 'productCenter.PassedTheReview'
      break
    case 3:
      // val = '审核驳回'
      val = 'productCenter.ReviewDismissed'
      break
    case 4:
      // val = '已撤销'
      val = 'productCenter.Revoked'
      break
    case 5:
      // val = '录入中'
      val = 'productCenter.Inputting'
      break
    case 6:
      // val = '无需审核'
      val = 'productCenter.NoNeedToAudit'
      break
    case 7:
      // val = '黑名单'
      val = 'productCenter.Blacklist'
      break
    default:
      val = ''
  }
  return val
}
/**
 *  收付款-审核状态
 */
export function putPayStatus(val) {
  switch (val) {
    case '1':
      val = '审核中'
      break
    case '2':
      val = '使用中'
      break
    case '3':
      val = '审核驳回'
      break
    case '4':
      val = '已作废'
      break
    default:
      val = ''
  }
  return val
}

/**
 * 客户类型
 */
export function customerTypeStatus(val) {
  switch (val) {
    case 0:
      val = '潜在客户'
      break
    case 1:
      val = '正式客户'
      break
    case 2:
      val = '黑名单'
      break
    default:
      val = ''
  }
  return val
}

export function customerTypeStatusLang(val) {
  switch (val) {
    case 0:
      val = 'cusDetail.PotentialCustomers'
      break
    case 1:
      // val = '正式客户'
      val = 'cusDetail.OfficialCustomer'
      break
    case 2:
      // val = '黑名单'
      val = 'selOpt.Blacklistcustomers'
      break
    default:
      val = ''
  }
  return val
}


export function companyTypeStatusLang(val) {
  switch (val) {
    case 1:
      // val = '正式客户'
      val = 'cusDetail.OfficialCompany'
      break
    case 0:
      // val = '潜在客户'
      val = 'cusDetail.PotentialCompany'
      break
    case 2:  //黑名单
      val = 'cusDetail.blacklistcompany'
      break
    default:
      val = ''
  }
  return val
}




export function customerStatusLang(val) {
  switch (val) {
    case 1: //已完成

      val = 'orderList.Completed'
      break
    case 2: // 修改审核中

      val = 'cusDetail.Modifyingandreviewing'
      break
    case 3:  //转入黑名单审核中
      val = 'cusDetail.Transferredtoblacklistreview'
      break
    case 4:  //审核驳回
      val = 'cusDetail.Reviewrejected'
      break
    default:
      val = ''
  }
  return val
}


// export function customerTypeStatusLang(val) {
//   switch (val) {
//     case 0:
//       val = 'selOpt.Potentialcustomers'
//       break
//     case 1:
//       val = 'selOpt.Formalcustomers'
//       break
//     case 2:
//       val = 'selOpt.Blacklistcustomers'
//       break
//     default:
//       val = ''
//   }
//   return val
// }

/**
 * 客户转让状态
 */
export function customerManageEnum(val) {
  switch (val) {
    case 600001:
      val = '创建'
      break
    case 600002:
      val = '认领'
      break
    case 600003:
      val = '转让'
      break
    case 600004:
      val = '分管'
      break
    case 600005:
      val = '转入公海'
      break
    case 600006:
      val = '移除分管'
      break
    default:
      val = ''
  }
  return val
}

// create: 'Create',
// claim: 'Claim',
// transfer: 'Transfer',
// Incharge: 'Incharge',
// ThrownBackIntoTheOpenSea: 'Thrown back into the high sea',
// removecharge: 'Remove in charge',
export function customerManageEnumLang(val) {
  switch (val) {
    case 600001:
      // val = '创建'
      val = 'other.create'
      break
    case 600002:
      // val = '认领'
      val = 'other.claim'
      break
    case 600003:
      // val = '转让'
      val = 'other.transfer'
      break
    case 600004:
      // val = '分管'
      val = 'other.Incharge'
      break
    case 600005:
      // val = '转入公海'
      val = 'other.ThrownBackIntoTheOpenSea'
      break
    case 600006:
      // val = '移除分管'
      val = 'other.removecharge'
      break
    default:
      val = ''
  }
  return val
}
/**
 * 订单状态
 */
export function orderStatus(val) {
  switch (val) {
    case '1':
      val = '录入中'
      break
    case '2':
      val = '已驳回'
      break
    case '3':
      val = '已撤销'
      break
    case '4':
      val = '审核中'
      break
    case '5':
      val = '已通过'
      break
    case '6':
      val = '待发货'
      break
    case '8':
      val = '待收货'
      break
    case '9':
      val = '已收货'
      break
    case '10':
      val = '已完成'
      break
    case '11':
      val = '已作废'
      break
    default:
      val = ''
  }
  return val
}

export function orderStatusLang(val) {
  switch (val) {
    case '1':
      // val = '录入中'
      val = 'productCenter.Inputting'
      break
    case '2':
      // val = '已驳回'
      val = 'other.Rejected'
      break
    case '3':
      // val = '已撤销'
      val = 'productCenter.Revoked'
      break
    case '4':
      // val = '审核中'
      val = 'productCenter.InReview'
      break
    case '5':
      // val = '已通过'
      val = 'productCenter.PassedTheReview'
      break
    case '6':
      // val = '待发货'
      val = 'other.Tobedelivered'
      break
    case '8':
      // val = '待收货'
      val = 'other.Waitingfordelivery'
      break
    case '9':
      // val = '已收货'
      val = 'other.Receivedgoods'
      break
    case '10':
      // val = '已完成'
      val = 'other.Completed'
      break
    case '11':
      // val = '已作废'
      val = 'other.Invalid'
      break
    default:
      val = ''
  }
  return val
}

/**
 * 询盘状态
 */
export function enquiryStatus(val) {
  switch (val) {
    case 1:
      val = '作废'
      break
    case 2:
      val = '询盘失败'
      break
    case 3:
      val = '手动完成'
      break
    case 4:
      val = '待报价'
      break
    case 5:
      val = '已报价'
      break
    case 6:
      val = '已下单'
      break
    default:
      val = ''
  }
  return val
}

export function enquiryStatusLang(val) {
  switch (val) {
    case 1:
      // val = '作废'
      val = 'other.Invalid'
      break
    case 2:
      // val = '询盘失败'
      val = 'other.inquiryfailed'
      break
    case 3:
      // val = '手动完成'
      val = 'other.Donebyhand'
      break
    case 4:
      // val = '待报价'
      val = 'other.Tobebid'
      break
    case 5:
      // val = '已报价'
      val = 'other.Alreadyoffer'
      break
    case 6:
      val = 'other.Haveorder'
      break
    default:
      val = ''
  }
  return val
}

/**
 * 订单状态
 */
export function orderSendStatus(val, lang) {
  switch (val) {
    case '0':
      val = lang === 'en' ? 'To Be Processed' : '待处理'
      break
    case '1':
      val = lang === 'en' ? 'Partially Delivery' : '部分出库'
      break
    case '2':
      val = lang === 'en' ? 'Out Of Warehouse' : '已出库'
      break
    case '3':
      val = lang === 'en' ? 'Partially Purchase' : '部分采购'
      break
    case '4':
      val = lang === 'en' ? 'In Procurement' : '采购中'
      break
    case '5':
      val = lang === 'en' ? 'Supplier Deferment' : '供应商延期'
      break
    case '6':
      val = lang === 'en' ? 'Partially Purchase Completed' : '部分采购完成'
      break
    case '7':
      val = lang === 'en' ? 'Purchase Completed' : '采购完成'
      break
    case '20':
      val = lang === 'en' ? 'Pending Shipment' : '待发货'
      break
    case '21':
      val = lang === 'en' ? 'In Transport' : '运输中'
      break
    case '29':
      val = lang === 'en' ? 'Delivered' : '已送达'
      break
    default:
      val = ''
  }
  return val
}

/**
 *  运费-计费方式
 */
export function freightType(val) {
  switch (val) {
    case 1:
      val = '体积'
      break
    case 2:
      val = '毛重、体积中（体积/6000）'
      break
    case 3:
      val = '毛重、体积中（体积/5000）'
      break
    case 4:
      val = '体积/毛重'
      break
    default:
      val = ''
  }
  return val
}

/**
 *  采购订单状态
 */
export function purchaseOrderStatus(val) {
  switch (val) {
    case 1:
      val = '录入中'
      break
    case 2:
      val = '已驳回'
      break
    case 3:
      val = '已撤销'
      break
    case 4:
      val = '审核中'
      break
    case 5:
      val = '采购中'
      break
    case 6:
      val = '验货中'
      break
    case 7:
      val = '已完成'
      break
    case 8:
      val = '已作废'
      break
    case 9:
      val = '销售订单异常'
      break
    case 10:
      val = '供应商延期'
      break
    case 11:
      val = '已入库'
      break
    case 12:
      val = '入库异常'
      break
    default:
      val = ''
  }
  return val
}
/**
 *  采购订单状态 中英文
 */
export function purchaseOrderStatusTranlate(val) {
  switch (Number(val)) {
    case 1:
      // val = '录入'
      val = 'productDetail.Input'
      break
    case 2:
      // val = '已驳回'
      val = 'productDetail.Rejected'
      break
    case 3:
      // val = '已撤销'
      val = 'productDetail.Revoked'
      break
    case 4:
      // val = '审核中'
      val = 'productDetail.InReview'
      break
    case 5:
      // val = '采购中'
      val = 'productDetail.InProcurement'
      break
    case 6:
      // val = '验货中'
      val = 'productDetail.UnderInspection'
      break
    case 7:
      // val = '已完成'
      val = 'productDetail.Completed'
      break
    case 8:
      // val = '已作废'
      val = 'productDetail.Voided'
      break
    case 9:
      // val = '销售订单异常'
      val = 'productDetail.SalesOrderException'
      break
    case 10:
      // val = '供应商延期'
      val = 'productDetail.SupplierDeferment'
      break
    case 11:
      // val = '已入库'
      val = 'productDetail.BeenStorage'
      break
    case 12:
      // val = '入库异常'
      val = 'productDetail.Input'
      break
    default:
      val = ''
  }
  return val
}
