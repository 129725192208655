<template>
  <div class="pro_material">
    <el-row :gutter="20" class="num-total">
      <el-col :span="4">
        <h4>{{ enquiryTotal.enquiryNum || 0 }}</h4>
        <!-- <p>询盘数</p> -->
        <p>{{ $t('cusDetail.NumberOfEnquiries') }}</p>
      </el-col>
      <el-col :span="4">
        <h4>{{ enquiryTotal.enquiryCompleteNum || 0 }}</h4>
        <!-- <p>成交数</p> -->
        <p>{{ $t('cusDetail.NumberOfTransactions') }}</p>
      </el-col>
    </el-row>
    <el-table class="mt15" :data="enquiryData" border style="width: 100%">
      <el-table-column
        type="index"
        width="60"
        align="center"
        label="#"
      ></el-table-column>
      <!-- label="询盘单号" -->
      <el-table-column
        prop="enquiryCode"
        align="center"
        :label="$t('cusDetail.InquiryNumber')"
      >
        <template slot-scope="scope">
          <span
            :class="[scope.row.skip === 1 ? 'blue-text' : '']"
            @click="goToPage(scope.row.enquiryId, scope.row.skip)"
          >
            {{ scope.row.enquiryCode }}
          </span>
        </template>
      </el-table-column>
      <!-- label="产品编号" -->
      <el-table-column
        align="center"
        prop="productCode"
        :label="$t('cusDetail.ProductCode')"
      ></el-table-column>
      <!-- label="产品名称" -->

      <el-table-column
        align="center"
        prop="productEn"
        :label="$t('cusDetail.ProductName')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- label="询盘数量" -->

      <el-table-column
        align="center"
        prop="amount"
        :label="$t('cusDetail.InquiryQuantity')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 状态(1作废，2询盘失败，3完成，4待报价，5已报价) -->
      <!-- label="询盘状态" -->
      <el-table-column
        align="center"
        prop="enquiryStatus"
        :label="$t('cusDetail.InquiryStatus')"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ $t(enquiryStatus(row.enquiryStatus)) }}
        </template>
      </el-table-column>
      <!-- label="询盘日期" -->

      <el-table-column
        align="center"
        prop="createTime"
        :label="$t('cusDetail.InquiryDate')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- label="客户代表" -->

      <el-table-column
        align="center"
        prop="businessName"
        :label="$t('cusDetail.CustomerRepresentative')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ getName(scope.row, 'businessName') }}
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      v-show="total > 0"
      :limit.sync="queryForm.pageLe"
      :page.sync="queryForm.pageNo"
      :total="total"
      @pagination="proSelectEnquiryProductPageVO"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Index'
import {
  customerGetEnquiryInfoVO,
  customerGetEnquiryInfoPageVO,
} from '@/api/client-supplier/client-manage'
import { enquiryStatusLang } from '@/utils/en-match-zh'
import nameMixin from '@/utils/name-mixin'

export default {
  name: 'EnquiryRecord',
  mixins: [nameMixin],
  components: { Pagination },
  data() {
    return {
      queryForm: {
        pageLe: 10,
        pageNo: 1, //当前页数
      },
      total: 0,
      infoId: '', //产品id
      enquiryTotal: {}, //询盘的一些总计
      enquiryData: [], //询盘的记录列表
    }
  },

  created() {
    if (this.$route.query.customerId) {
      this.infoId = this.$route.query.customerId
      //客户询盘记录与询盘记录列表
      this.proGetEnquiryProductVO()
      this.proSelectEnquiryProductPageVO()
    }
  },
  mounted() {},
  methods: {
    //询盘记录
    async proGetEnquiryProductVO() {
      let response = await customerGetEnquiryInfoVO({ infoId: this.infoId })
      if ((response.code = '000000')) {
        this.enquiryTotal = response.data
      }
    },
    //询盘记录列表
    async proSelectEnquiryProductPageVO() {
      let response = await customerGetEnquiryInfoPageVO({
        ...this.queryForm,
        infoId: this.infoId,
      })
      if ((response.code = '000000')) {
        this.enquiryData = response.data.data
        this.total = response.data.total
      }
    },
    //询盘单号跳转
    goToPage(code, skip) {
      let routeData = this.$router.resolve({
        path: '/order/inquiryManage/inquiryDetail',
        query: {
          enquiryId: code,
          noReturn: true,
        },
      })
      if (skip === 1) {
        window.open(routeData.href, '_blank')
      }
    },
    // 查看
    lookClick() {
      return this.$baseMessage(
        '待开发...',
        'error',
        false,
        'erp-hey-message-error'
      )
    },
    //询盘状态
    enquiryStatus(val) {
      return enquiryStatusLang(val)
    },
  },
}
</script>

<style scoped lang="scss">
.num-total {
  padding-left: 15px;
  > .el-col {
    border: 1px solid #ededed;
    padding: 15px 20px;
    padding-left: 30px !important;
    border-radius: 2px;
    h4 {
      font-size: 30px;
    }
    p {
      margin-top: 5px;
    }
  }
  // > .el-col:nth-child(2) {
  //   h4 {
  //     color: green;
  //   }
  // }
  // > .el-col:last-child {
  //   h4 {
  //     color: red;
  //   }
  // }
  .el-col + .el-col {
    margin-left: 10px;
  }
}
::v-deep {
  .el-tabs__content {
    padding-left: 50px !important;
  }
}
</style>
