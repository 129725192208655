<template>
  <div class="customer-info">
    <el-form :model="customerInfo" :rules="rules" ref="ruleForm" class="mb30">
      <!-- 基本信息 -->
      <el-row>
        <p class="f_s_15">{{ $t('other.baseInfo') }}</p>
        <table class="join-table mt20">
          <tr>
            <!-- <td>电子邮箱</td> -->
            <td>{{ $t('cusManage.CustomerMailbox') }}</td>
            <td>
              <p v-if="!isEdit">{{ customerInfo.email }}</p>
              <el-row v-else>
                <el-form-item prop="email">
                  <el-input
                    v-model="customerInfo.email"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                    disabled
                  />
                </el-form-item>
              </el-row>
            </td>
            <td>
              <i style="color: #ff4d4f">*</i>
              <!-- 客户姓名 -->
              {{ $t('cusManage.CustomerName') }}
            </td>
            <td>
              <p v-if="!isEdit">{{ customerInfo.customerName }}</p>
              <el-row v-else>
                <el-form-item
                  prop="customerName"
                  :rules="[
                    {
                      required: true,
                      trigger: 'blur',
                      message: this.$t('placeholder.plsInput'),
                    },
                  ]"
                >
                  <!-- placeholder="请输入" -->
                  <el-input
                    v-model="customerInfo.customerName"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                    disabled
                  ></el-input>
                </el-form-item>
              </el-row>
            </td>
            <td>
              <i style="color: #ff4d4f">*</i>
              <!-- 公司名称 -->
              {{ $t('cusDetail.CompanyName') }}
            </td>
            <td>
              <p v-if="!isEdit">{{ customerInfo.companyName }}</p>
              <el-row v-else>
                <el-form-item
                  prop="companyName"
                  :rules="[
                    {
                      required: true,
                      trigger: 'blur',
                      message: this.$t('placeholder.plsInput'),
                    },
                  ]"
                >
                  <el-input
                    v-model="customerInfo.companyName"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                    type="textarea"
                    :rows="2"
                    maxlength="500"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-row>
            </td>
            <!-- <td>负责人</td> -->
            <td>{{ $t('cusDetail.ResponsiblePerson') }}</td>
            <td>
              {{
                getName(customerInfo, 'belongerName') ||
                $t('other.Noresponsibleperson')
              }}
              <span
                v-if="!isEdit"
                @click="leadingHistoryClick(customerInfo.infoId)"
                class="blue-text"
              >
                <!-- 查看历史 -->
                {{ $t('other.showHistory') }}
              </span>
              <!-- <span v-else @click="transferClick" class="blue-text">转让</span> -->
            </td>
          </tr>
          <tr>
            <!-- <td>公司地址</td> -->
            <td>{{ $t('cusDetail.CompanyAddress') }}</td>
            <td>
              <p v-if="!isEdit">
                <span v-if="customerInfo.companyAddress">
                  {{ customerInfo.companyAddress }}
                </span>
                <!-- <span v-else class="no-text">未填写</span> -->
                <span v-else class="no-text">
                  {{ $t('other.noWrite') }}
                </span>
              </p>
              <el-row v-else>
                <el-form-item prop="companyAddress">
                  <el-input
                    v-model="customerInfo.companyAddress"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-row>
            </td>
            <!-- <td>客户来源</td> -->
            <td>{{ $t('cusDetail.CustomerSource') }}</td>
            <td>
              <!-- customerSourceName customerSourceId -->
              <p v-if="!isEdit">
                <span v-if="customerInfo.customerSourceName">
                  {{ customerInfo.customerSourceName }}
                </span>
                <!-- <span v-else class="no-text">未填写</span> -->
                <span v-else class="no-text">
                  {{ $t('other.noWrite') }}
                </span>
              </p>
              <el-row v-else>
                <el-form-item prop="customerSourceId">
                  <el-select
                    v-model="customerInfo.customerSourceId"
                    :placeholder="$t('placeholder.plsInput')"
                    class="w100"
                  >
                    <el-option
                      v-for="item in customerSource"
                      :key="item.sourceId"
                      :label="
                        lang === 'en' ? item.sourceNameEn : item.sourceName
                      "
                      :value="item.sourceId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
            </td>
            <!-- <td>客户级别</td> -->
            <td>{{ $t('cusDetail.CustomerLevel') }}</td>
            <td>
              <el-rate
                v-if="!isEdit"
                v-model="customerInfo.customerRank"
                disabled
                text-color="#ff9900"
              ></el-rate>
              <el-row v-else>
                <el-select
                  v-model="customerInfo.customerRank"
                  :placeholder="$t('placeholder.plsSel')"
                  class="w100"
                >
                  <el-option
                    v-for="item in customerRankOptions"
                    :key="item.value"
                    :label="$t(item.label)"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-row>
            </td>
            <!-- <td>客户类型</td> -->
            <td>{{ $t('cusDetail.CustomerType') }}</td>
            <td>{{ $t(customerTypeStatus(customerInfo.customerType)) }}</td>
          </tr>
          <tr>
            <!-- <td>邮编</td> -->
            <td>{{ $t('cusDetail.ZipCode') }}</td>
            <td>
              <p v-if="!isEdit">
                <span v-if="customerInfo.postcode">
                  {{ customerInfo.postcode }}
                </span>
                <!-- <span v-else class="no-text">未填写</span> -->
                <span v-else class="no-text">
                  {{ $t('other.noWrite') }}
                </span>
              </p>
              <el-row v-else>
                <el-form-item prop="postcode">
                  <el-input
                    v-model="customerInfo.postcode"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-row>
            </td>
            <!-- <td>网址</td> -->
            <td>{{ $t('cusDetail.WebAddress') }}</td>
            <td>
              <p v-if="!isEdit">
                <span v-if="customerInfo.website">
                  {{ customerInfo.website }}
                </span>
                <!-- <span v-else class="no-text">未填写</span> -->
                <span v-else class="no-text">
                  {{ $t('other.noWrite') }}
                </span>
              </p>
              <el-row v-else>
                <el-form-item prop="website">
                  <el-input
                    v-model="customerInfo.website"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-row>
            </td>
            <!-- <td>传真</td> -->
            <td>{{ $t('cusDetail.FaxNumber') }}</td>
            <td>
              <p v-if="!isEdit">
                <span v-if="customerInfo.faxNumber">
                  {{ customerInfo.faxNumber }}
                </span>
                <!-- <span v-else class="no-text">未填写</span> -->
                <span v-else class="no-text">
                  {{ $t('other.noWrite') }}
                </span>
              </p>
              <el-row v-else>
                <el-form-item prop="faxNumber">
                  <el-input
                    v-model="customerInfo.faxNumber"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-row>
            </td>
            <!-- <td>备注</td> -->
            <td>{{ $t('cusDetail.Remark') }}</td>
            <td>
              <el-row v-if="!isEdit">
                <tooltip-over
                  class="c_pointer"
                  v-if="customerInfo.remark"
                  :content="customerInfo.remark || '--'"
                  refName="tooltipOver5"
                ></tooltip-over>
                <!-- <span v-else class="no-text">未填写</span> -->
                <span v-else class="no-text">
                  {{ $t('other.noWrite') }}
                </span>
              </el-row>
              <el-row v-else>
                <el-form-item prop="remark">
                  <el-input
                    :disabled="!permissionsTab('Client:Remark')"
                    v-model="customerInfo.remark"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </el-row>
            </td>
          </tr>
        </table>
      </el-row>
      <!-- 联系人信息 -->
      <el-row class="mt30">
        <!-- <p class="f_s_15">联系人信息</p> -->
        <p class="f_s_15">{{ $t('cusDetail.ContactInformation') }}</p>
        <div class="c-table-wrap">
          <table class="custom-table join-table mt20">
            <thead>
              <tr>
                <!-- <th width="20">序号</th> -->
                <th :width="lang === 'en' ? 100 : 20">
                  {{ $t('cusDetail.SerialNumber') }}
                </th>
                <th width="150">
                  <span>
                    <i>*</i>
                    <!-- 姓名 -->
                    {{ lang === 'en' ? 'Name' : '姓名' }}
                    <!-- {{ $t('cusDetail.SerialNumber') }} -->
                  </span>
                </th>
                <th width="200" minWidth="150">
                  <span>
                    <i>*</i>
                    <!-- 联系电话 -->
                    {{ $t('cusDetail.ContactNumber') }}
                  </span>
                </th>
                <!-- <th width="150" minWidth="150">电子邮箱</th>
                <th width="120">WhatsApp</th>
                <th width="200" minWidth="150">Skype</th>
                <th width="120">WeChat</th>
                <th width="120">是否主要</th>
                <th width="80" v-if="isEdit">操作</th> -->
                <!-- <th width="150" minWidth="150">电子邮箱</th> -->
                <th width="150" minWidth="150">
                  {{ lang === 'en' ? 'MailAccount' : '电子邮箱' }}
                </th>
                <th width="120">{{ $t('cusDetail.Skype') }}</th>
                <th width="200" minWidth="150">
                  {{ $t('cusDetail.WhatsApp') }}
                </th>
                <th width="120">{{ $t('cusDetail.Wechat') }}</th>
                <th width="120">{{ $t('cusDetail.WhetherTheMain') }}</th>
                <th width="80" v-if="isEdit">
                  {{ $t('cusDetail.Operate') }}
                </th>
              </tr>
            </thead>
            <tbody v-if="customerInfo.linkmans && customerInfo.linkmans.length">
              <tr v-for="(item, i) in customerInfo.linkmans" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  <el-row v-if="!isEdit">
                    {{ customerInfo.linkmans[i].linkman }}
                  </el-row>

                  <el-form-item
                    v-else
                    :prop="'linkmans.' + i + '.linkman'"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsInput'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="customerInfo.linkmans[i].linkman"
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-row v-if="!isEdit">
                    {{ customerInfo.linkmans[i].phone }}
                  </el-row>
                  <el-form-item
                    v-else
                    :prop="'linkmans.' + i + '.phone'"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsInput'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="customerInfo.linkmans[i].phone"
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-row v-if="!isEdit">
                    <p
                      class="email-skip"
                      @click="emailClick(customerInfo.linkmans[i].email)"
                    >
                      {{ customerInfo.linkmans[i].email }}
                    </p>
                  </el-row>
                  <el-form-item :prop="'linkmans.' + i + '.email'" v-else>
                    <el-input
                      v-model="customerInfo.linkmans[i].email"
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                      :disabled="i === 0 ? true : false"
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-row v-if="!isEdit">
                    {{ customerInfo.linkmans[i].whatsapp }}
                  </el-row>
                  <el-form-item :prop="'linkmans.' + i + '.whatsapp'" v-else>
                    <el-input
                      v-model="customerInfo.linkmans[i].whatsapp"
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-row v-if="!isEdit">
                    {{ customerInfo.linkmans[i].skype }}
                  </el-row>
                  <el-form-item :prop="'linkmans.' + i + '.skype'" v-else>
                    <el-input
                      v-model="customerInfo.linkmans[i].skype"
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-row v-if="!isEdit">
                    {{ customerInfo.linkmans[i].wechat }}
                  </el-row>
                  <el-form-item :prop="'linkmans.' + i + '.wechat'" v-else>
                    <el-input
                      v-model="customerInfo.linkmans[i].wechat"
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-row v-if="!isEdit">
                    <el-switch
                      v-model="customerInfo.linkmans[i].major"
                      disabled
                      :active-value="1"
                      :inactive-value="0"
                    />
                  </el-row>

                  <el-form-item :prop="'linkmans.' + i + '.major'" v-else>
                    <el-switch
                      v-model="customerInfo.linkmans[i].major"
                      :active-value="1"
                      :inactive-value="0"
                      @change="changeSwitch($event, i)"
                    ></el-switch>
                  </el-form-item>
                </td>
                <td class="text-center" v-if="isEdit">
                  <!-- content="删除" -->
                  <el-tooltip
                    :content="$t('iconbtn.del')"
                    placement="top"
                    v-if="i !== 0"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      plain
                      @click="delLinkMan(i)"
                    ></el-button>
                  </el-tooltip>
                  <el-row v-else>/</el-row>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <el-row
          class="mt20"
          type="flex"
          align="middle"
          justify="center"
          v-if="isEdit"
        >
          <!-- <el-button @click="addLinkManClick">新增一行</el-button> -->
          <el-button @click="addLinkManClick">
            {{ $t('cusDetail.Add') }}
          </el-button>
        </el-row>
      </el-row>
      <!-- 付款方式 -->
      <el-row class="mt30">
        <!-- <p class="f_s_15">付款方式</p> -->
        <p class="f_s_15">{{ $t('cusDetail.PaymentMethod') }}</p>
        <el-row class="mt20" v-if="isEdit">
          <el-form-item prop="payId" style="margin-bottom: 0">
            <!-- placeholder="请选择" -->
            <el-select
              v-model="customerInfo.payId"
              :placeholder="$t('placeholder.plsSel')"
              multiple
              collapse-tags
              style="width: 30%"
              class="logMgCls"
              @change="payChange"
            >
              <el-option
                v-for="item in payWaySelect"
                :key="item.paymentId"
                :label="lang === 'en' ? item.paywayEn : item.payway"
                :value="item.paymentId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <!-- table -->
        <el-table
          class="mt20"
          border
          :data="customerInfo.pays"
          style="width: 70%"
        >
          <!-- label="序号"   -->
          <el-table-column
            type="index"
            :width="lang === 'en' ? 150 : 50"
            :label="$t('cusDetail.SerialNumber')"
            align="center"
          ></el-table-column>
          <!-- label="金额区间" -->
          <el-table-column
            align="center"
            :label="$t('cusDetail.AmountRange')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{
                lang == 'en'
                  ? scope.row.payway.split('/')[0] &&
                    scope.row.payway.split('/')[0].replace('全部', 'All')
                  : scope.row.payway.split('/')[0]
              }}
            </template>
          </el-table-column>
          <!-- label="付款方式" -->
          <el-table-column
            align="center"
            show-overflow-tooltip
            :label="$t('cusDetail.PaymentMethod')"
          >
            <template slot-scope="scope">
              {{ scope.row.payway.split('/')[1] }}
            </template>
          </el-table-column>
          <!-- label="操作" -->
          <el-table-column
            align="center"
            :label="$t('cusDetail.Operate')"
            v-if="isEdit"
            width="100"
          >
            <template slot-scope="scope">
              <!-- content="删除" -->
              <el-tooltip placement="top" :content="$t('iconbtn.del')">
                <el-button
                  type="danger"
                  plain
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  @click="payDel(scope.$index)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 收货地址 -->
      <el-row class="mt30">
        <el-row type="flex" align="middle">
          <!-- <p class="f_s_15">收货地址</p> -->
          <p class="f_s_15">{{ $t('cusDetail.ShippingAddress') }}</p>
          <el-button class="ml20" @click="addAddressClick" v-if="isEdit">
            <!-- 新增收货地址 -->
            {{ $t('cusDetail.AddShippingAddress') }}
          </el-button>
        </el-row>

        <el-table class="mt20" border :data="customerInfo.addressConfigVOS">
          <!-- label="序号" -->
          <el-table-column
            type="index"
            width="60"
            :label="$t('cusDetail.SerialNumber')"
            align="center"
          ></el-table-column>
          <!-- label="收货地址" -->
          <el-table-column
            prop="address"
            align="center"
            :label="$t('cusDetail.ShippingAddress')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.address }}</template>
          </el-table-column>
          <!-- label="收货公司" -->
          <el-table-column
            prop="companyName"
            align="center"
            :label="$t('cusDetail.ReceivingCompany')"
          >
            <template slot-scope="scope">{{ scope.row.companyName }}</template>
          </el-table-column>
          <!-- label="收货人" -->
          <el-table-column
            prop="consigneeName"
            align="center"
            :label="$t('cusDetail.Receiver')"
          >
            <template slot-scope="scope">
              {{ scope.row.consigneeName }}
            </template>
          </el-table-column>
          <!-- label="联系电话" -->
          <el-table-column
            prop="consigneePhone"
            align="center"
            :label="$t('cusDetail.ContactNumber')"
          >
            <template slot-scope="scope">
              {{ scope.row.consigneePhone }}
            </template>
          </el-table-column>
          <!-- label="是否默认" -->
          <el-table-column
            prop="defaultAdress"
            align="center"
            :label="$t('cusDetail.Default')"
          >
            <template slot-scope="scope">
              <!-- {{ scope.row.defaultAdress === 1 ? '是' : '否' }} -->
              {{
                scope.row.defaultAdress === 1 ? $t('other.yes') : $t('other.no')
              }}
            </template>
          </el-table-column>
          <!-- label="操作" -->
          <el-table-column
            :label="$t('cusDetail.Operate')"
            align="center"
            width="150"
            fixed="right"
            :resizable="false"
            v-if="isEdit"
          >
            <template slot-scope="scope">
              <!-- content="设为默认地址" -->
              <el-tooltip
                :content="$t('iconbtn.setAsTheDefaultAddress')"
                placement="top"
              >
                <el-button
                  @click="collectAddress(scope.$index, scope.row)"
                  :class="scope.row.defaultAdress === 1 ? 'icon-y' : ''"
                  size="mini"
                  icon="el-icon-star-off"
                  circle
                  plain
                ></el-button>
              </el-tooltip>
              <!-- content="编辑" -->
              <el-tooltip placement="top" :content="$t('iconbtn.edit')">
                <el-button
                  type="primary"
                  @click="editAddress(scope.row, scope.$index)"
                  size="mini"
                  icon="el-icon-edit"
                  circle
                  plain
                ></el-button>
              </el-tooltip>
              <!-- content="删除" -->
              <el-tooltip placement="top" :content="$t('iconbtn.del')">
                <el-button
                  type="danger"
                  @click="delAddress(scope.$index, scope.row)"
                  size="mini"
                  icon="el-icon-delete"
                  circle
                  plain
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 跟进记录 -->
      <!-- <el-row class="mt30" style="margin-bottom: 100px" >
        <p class="f_s_15">跟进记录</p>
        <el-table class="mt20" border :data="customerInfo.follows">
          <el-table-column
            prop="linkman"
            align="center"
            label="联系人"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.linkman }}</template>
          </el-table-column>
          <el-table-column prop="linkType" align="center" label="联系方式">
            <template slot-scope="scope">{{ scope.row.linkType }}</template>
          </el-table-column>
          <el-table-column
            prop="theme"
            align="center"
            label="跟进主题"
            width="200"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">{{ scope.row.theme }}</template>
          </el-table-column>
          <el-table-column prop="direction" align="center" label="跟进方向">
            <template slot-scope="scope">{{ scope.row.direction }}</template>
          </el-table-column>
          <el-table-column prop="followGoal" align="center" label="跟进目的">
            <template slot-scope="scope">{{ scope.row.followGoal }}</template>
          </el-table-column>
          <el-table-column prop="followTime" align="center" label="跟进时间">
            <template slot-scope="scope">{{ scope.row.followTime }}</template>
          </el-table-column>
          <el-table-column prop="orderType" align="center" label="关联订单类型">
            <template slot-scope="scope">{{ scope.row.orderType }}</template>
          </el-table-column>
          <el-table-column prop="serial" align="center" label="订单编号">
            <template slot-scope="scope">{{ scope.row.serial }}</template>
          </el-table-column>
          <el-table-column
            prop="remark"
            align="center"
            label="备注"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.remark }}</template>
          </el-table-column>
          <el-table-column prop="creatorName" align="center" label="创建人">
            // <template slot-scope="scope">{{ scope.row.creatorName }}</template>
            <template slot-scope="scope">{{ getName(scope.row, 'createName') }}</template>
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="创建时间">
            <template slot-scope="scope">{{ scope.row.createTime }}</template>
          </el-table-column>
        </el-table>
      </el-row> -->
    </el-form>
    <!-- 操作按钮 -->
    <el-row class="operate">
      <!-- <el-button type="primary" @click="save" v-if="isEdit">确认</el-button>
      <el-button @click="goBack">取消</el-button> -->
      <el-button type="primary" :loading="loading" @click="save" v-if="isEdit">
        {{ $t('cusDetail.Confirm') }}
      </el-button>
      <el-button @click="goBack">{{ $t('cusDetail.Cancel') }}</el-button>
    </el-row>
    <!-- 查看历史 -->
    <el-drawer
      title="历史记录"
      :visible.sync="historyDrawer"
      :before-="historyClose"
    >
      <LeadingHistoryDrawer
        ref="LeadingHistoryDrawer"
        @cancelClick="historyClose"
        :infoId="infoId"
      />
    </el-drawer>

    <!-- 新增编辑收货地址 -->
    <AddressAdd ref="AddressAdd" @addressConfigSave="addressConfigSave" />

    <!-- 转让分管 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import {
    getDetailForEditShow,
    clientUpdatePO,
    customerTransfer,
  } from '@/api/client-supplier/client-manage'
  import LeadingHistoryDrawer from './leading-history-drawer'
  import AddressAdd from './address-add-model'
  import { customerTypeStatusLang } from '@/utils/en-match-zh'
  import tooltipOver from '@/components/base-tooltip'
  import UserChoose from '@/components/userChoose'
  import { mapGetters } from 'vuex'
  import { langMixin } from '@/utils/lang-util'
  import nameMixin from '@/utils/name-mixin'

  export default {
    name: 'ClientManageDetailMsg',
    components: { LeadingHistoryDrawer, AddressAdd, tooltipOver, UserChoose },
    mixins: [langMixin, nameMixin],
    props: {
      isEditParams: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        customerId: '',
        historyDrawer: false, //审核进度
        collectAddressIndex: null, // 收货地址索引
        linkManMajorIndex: null, // 联系人是否主要索引
        isEdit: false, //是否是编辑
        customerInfo: {}, //客户全部信息
        rules: {
          // customerName: [
          //   { required: true, message: '客户姓名不能为空', trigger: 'blur' },
          // ],
          // companyName: [
          //   { required: true, message: '公司名称不能为空', trigger: 'blur' },
          // ],
        },
        customerRankOptions: [
          //客户等级
          {
            value: 1,
            // label: '1级',
            label: 'selOpt.l1',
          },
          {
            value: 2,
            // label: '2级',
            label: 'selOpt.l2',
          },
          {
            value: 3,
            // label: '3级',
            label: 'selOpt.l3',
          },
          {
            value: 4,
            // label: '4级',
            label: 'selOpt.l4',
          },
          {
            value: 5,
            // label: '5级',
            label: 'selOpt.l5',
          },
        ],
        payWaySelect: [], //付款方式下拉
        addressStr: 'add',
        customerSource: [], //客户来源下拉
        infoId: '', //客户id
      }
    },
    computed: {
      ...mapGetters({
        permissions: 'user/permissions',
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
    },
    watch: {
      isEditParams: {
        immediate: true,
        deep: true,
        handler(value) {
          this.isEdit = value
        },
      },
    },
    created() {
      if (this.$route.query.operate === 'show') {
        this.isEdit = false
      } else {
        this.isEdit = true
      }
      if (this.$route.query.customerId) {
        this.customerId = this.$route.query.customerId
        this.getDetailForEditShow()

        //客户来源
        this.pubApi.customerSourceList({}).then((response) => {
          if (response.code === '000000') {
            this.customerSource = response.data
          }
        })
        //客户付款方式下拉
        this.pubApi
          .paymentList({
            payee: 1,
            status: 2,
            tenantId: this.userInfo.tenantId,
          })
          .then((response) => {
            if (response.code === '000000') {
              if (response?.data) {
                response.data = response.data.map((i) => {
                  i.paywayEn = i.payway.replace('全部', 'All')
                  return i
                })
              }
              this.payWaySelect = response.data
            }
          })
      }
    },
    mounted() {},
    methods: {
      clearValidate() {
        // 清空校验信息
        this.$refs.ruleForm.clearValidate()
      },
      // tabs权限控制
      permissionsTab(tab) {
        return this.permissions.some((item) => item == tab)
      },
      //客户详情
      async getDetailForEditShow() {
        let response = await getDetailForEditShow({ infoId: this.customerId })
        if (response?.code === '000000') {
          this.customerInfo = response.data
        }
      },
      //保存
      save() {
        let customerInfo = this.utils.deepCopy(this.customerInfo)
        this.customerSource.forEach((item) => {
          if (item.sourceId === customerInfo.customerSourceId) {
            customerInfo.customerSourceName = item.sourceName
            customerInfo.customerSourceNameEn = item.sourceNameEn
          }
        })
        customerInfo.receivingAddressConfigDTOS = customerInfo.addressConfigVOS
        customerInfo.linkmanDTOS = customerInfo.linkmans
        customerInfo.paymentId = customerInfo.payId

        if (!customerInfo.paymentId || customerInfo.paymentId.length === 0) {
          return this.$baseMessage(
            // '请选择一条付款方式',
            this.$t('reqmsg.$8'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        delete customerInfo.addressConfigVOS
        delete customerInfo.linkmans
        delete customerInfo.payId
        delete customerInfo.pays
        delete customerInfo.follows
        customerInfo.linkmanDTOS.forEach((item) => {
          if (item.major === '1') {
            item.major = Number(item.major)
          }
        })

        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            this.loading = true
            let response = await clientUpdatePO(customerInfo)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                // '保存成功',
                this.$t('reqmsg.$9'),
                'success',
                false,
                'erp-hey-message-success'
              )
              this.goBack()
            }
          }
        })
      },

      //转让弹框
      async transferClick() {
        this.$refs['UserChoose'].showAddEdit(
          'belongerUser',
          this.auditManageTags
        )
      },
      //组织架构返回//转让接口
      async chooseUser(userArr) {
        let userId = [userArr[0].id]
        let params = {
          infoId: [this.customerInfo.infoId],
          userIds: userId,
        }
        let response = await customerTransfer(params)
        if (response.code === '000000' && response.data === 1) {
          this.$baseMessage(
            // '转让成功',
            this.$t('reqmsg.$10'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.$router.push('/client-supplier/client-manage/index')
        } else {
          this.$baseMessage(
            // '转让失败',
            this.$t('reqmsg.$11'),
            'error',
            false,
            'erp-hey-message-error'
          )
        }
      },
      //邮件跳转
      emailClick(email) {
        let routeData = this.$router.resolve({
          name: 'MailReceiveAll',
          query: { searchValue: email },
        })
        window.open(routeData.href, '_blank')
      },
      //联系人删除
      delLinkMan(index) {
        this.customerInfo.linkmans.splice(index, 1)
      },
      //付款方式删除
      payDel(index) {
        this.customerInfo.pays.splice(index, 1)
        this.customerInfo.payId.splice(index, 1)
      },

      //付款方式选择
      payChange(val) {
        // let payDTOS = []
        // let qujian = []
        // this.payWaySelect.forEach((item, index) => {
        //   val.forEach((item2) => {
        //     if (item.paymentId === item2) {
        //       payDTOS.push(item)
        //     }
        //   })
        // })
        // payDTOS.forEach((item) => {
        //   item.paymentMethodName = item.payway
        //   item.intervalName = item.payway.split('/')[0]
        //   qujian.push(item.payway.split('/')[0])
        // })

        // if (new Set(qujian).size != qujian.length) {
        //   this.$set(
        //     this.customerInfo,
        //     'payId',
        //     this.customerInfo.payId.slice(0, this.customerInfo.payId.length - 1)
        //   )
        //   return this.$baseMessage(
        //     '相同的付款区间只能选择一个',
        //     'warning',
        //     false,
        //     'erp-hey-message-warning'
        //   )
        // }

        if (this.customerInfo.pays.length > 0) {
          this.customerInfo.payId.forEach((element) => {
            this.customerInfo.pays.forEach((item, index) => {
              if (element == item.paymentId) {
                this.customerInfo.pays.splice(index, 1)
              }
            })
          })
        }

        // 添加进表格
        this.customerInfo.payId.forEach((element) => {
          this.payWaySelect.forEach((item) => {
            if (element == item.paymentId) {
              let obj = {
                payway: item.payway,
                paymentId: item.paymentId,
              }
              this.customerInfo.pays.push(obj)
            }
          })
        })

        let deleteArr = []
        this.customerInfo.pays.forEach((item) => {
          deleteArr.push(item)
        })
        // 通过遍历 删除得到差集，就是被删除的项
        val.forEach((element) => {
          deleteArr.forEach((item, index) => {
            if (element == item.paymentId) {
              deleteArr.splice(index, 1)
            }
          })
        })

        this.customerInfo.pays.forEach((item, index) => {
          if (deleteArr.length == 1) {
            if (item.paymentId == deleteArr[0].paymentId) {
              this.customerInfo.pays.splice(index, 1)
            }
          }
        })
      },

      //联系人是否主要
      changeSwitch(val, index) {
        if (!val) return
        this.customerInfo.linkmans.forEach((v, eindex) => {
          if (index != eindex) {
            v.major = 0
          }
        })
      },

      //联系人是否主要
      linkmansMajorChange(i, val) {
        this.linkManMajorIndex = i
        this.customerInfo.linkmans.forEach((item, k) => {
          if (i == k) {
            //alert("k")
            this.customerInfo.linkmans[k] = {
              ...item,
              major: '1',
            }
          } else {
            this.customerInfo.linkmans[k] = {
              ...item,
              major: '0',
            }
          }
        })
      },

      //新增联系人
      addLinkManClick() {
        let obj = {
          linkman: '',
          email: '',
          phone: '',
          whatsapp: '',
          skype: '',
          wechat: '',
          major: 0,
        }
        this.customerInfo.linkmans.push(obj)
      },
      //删除联系人
      linkmanDel(index) {
        this.customerInfo.linkmans.splice(index, 1)
      },
      //负责人历史记录
      leadingHistoryClick(infoId) {
        this.infoId = infoId
        this.historyDrawer = true
      },
      //审核状态关闭
      historyClose() {
        this.historyDrawer = false
      },

      //收藏地址
      collectAddress(i, row) {
        this.collectAddressIndex = i
        let flag = false
        this.customerInfo.addressConfigVOS.forEach((item) => {
          if (item.defaultAdress === 1) {
            flag = true
          }
        })
        if (flag && row.defaultAdress !== 1) {
          // return this.$baseMessage(
          //   '默认地址只能有一个，请先取消其他默认地址！',
          //   'warning',
          //   false,
          //   'erp-hey-message-warning'
          // )
          this.customerInfo.addressConfigVOS.forEach((item) => {
            item.defaultAdress = 0
          })
        }

        if (row.defaultAdress === 1) {
          this.customerInfo.addressConfigVOS[i].defaultAdress = 0
        } else {
          this.customerInfo.addressConfigVOS[i].defaultAdress = 1
        }

        // this.customerInfo.addressConfigVOS.forEach((item, k) => {
        //   if (i == k) {
        //     this.customerInfo.addressConfigVOS[k] = {
        //       ...item,
        //       item: 1,
        //     }
        //   } else {
        //     this.customerInfo.addressConfigVOS[k] = {
        //       ...item,
        //       defaultAdress: 0,
        //     }
        //   }

        //})
      },

      //新增收货地址
      addAddressClick() {
        this.addressStr = 'add'
        this.$refs['AddressAdd'].showAddEdit()
      },

      // 编辑地址
      editAddress(row, index) {
        this.addressStr = 'edit'
        this.$refs['AddressAdd'].showAddEdit(row, index)
      },
      //地址返回
      addressConfigSave(form, index) {
        if (this.addressStr === 'add') {
          this.customerInfo.addressConfigVOS.push(form)
        } else {
          this.$set(this.customerInfo.addressConfigVOS, index, form)
          this.$forceUpdate()
        }
      },
      // 删除选中地址
      delAddress(i) {
        let that = this
        that
          .$confirm(
            // `是否确认删除?`,
            this.$t('reqmsg.$12'),
            // '提示',
            this.$t('other.info'),
            {
              // confirmButtonText: '确定',
              // cancelButtonText: '取消',
              confirmButtonText: this.$t('other.confirm'),
              cancelButtonText: this.$t('other.cancel'),
              type: 'warning',
            }
          )
          .then(() => {
            that.customerInfo.addressConfigVOS.splice(i, 1)
          })
          .catch(() => {})
      },

      //取消
      goBack() {
        this.$router.push('/client-supplier/client-manage/index')
      },
      //客户类型匹配
      customerTypeStatus(val) {
        return customerTypeStatusLang(val)
      },
    },
  }
</script>

<style scoped lang="scss">
  .customer-info {
    height: calc(100% - 550px);
    overflow-y: auto;
    position: relative;
    .operate {
      position: fixed;
      bottom: 20px;
      height: 50px;
      line-height: 50px;
      width: 81%;
      text-align: center;
      background: #fff;
      z-index: 999;
      border-top: 1px solid #ededed;
    }
  }
  .join-table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      height: 60px;
      border: 1px solid #f5f7fa;
    }
    th {
      height: 38px;
      line-height: 38px !important;
      font-weight: normal;
    }
    td:nth-child(even) {
      font-family: 'PingFang Bold';
      max-width: 300px;
      overflow: hidden;
      padding: 0 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    td:nth-child(odd) {
      width: 100px;
      text-align: center;
    }
  }
  .no-text {
    font-weight: normal;
    color: #909399;
  }
  .text-tooltip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .email-skip {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }
  ::v-deep {
    .join-table {
      .el-form-item--small.el-form-item {
        margin-bottom: 0 !important;
      }
      .el-input__inner {
        height: 33px !important;
      }
      .el-form-item__error {
        font-weight: normal !important;
      }
    }
    .logMgCls {
      .el-select__tags {
        > span {
          display: flex;
        }
      }
    }
    .el-select__tags-text {
      display: inline-block;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    // .el-select .el-tag__close.el-icon-close {
    //   top: -5px;
    // }
  }
</style>
