<template>
  <div
    :class="{ hidden: hidden, left: align === 'left' ? true : false }"
    class="pagination-container"
  >
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      :pager-count="5"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
  //import { scrollTo } from '@/utils/scroll-to'

  export default {
    name: 'Pagination',
    props: {
      total: {
        required: true,
        type: Number,
      },
      page: {
        type: Number,
        default: 1, // 默认第一页
      },
      limit: {
        type: Number,
        default: 20, // 默认每页20条
      },
      pageSizes: {
        type: Array,
        default() {
          return [10, 20, 30, 40, 50, 100, 200] // 默认显示可选的每页多少条数据
        },
      },
      layout: {
        type: String,
        default: 'total, sizes, prev, pager, next, jumper',
        // default: 'total, prev, pager, next, jumper'
      },
      background: {
        type: Boolean,
        default: true,
      },
      autoScroll: {
        type: Boolean,
        default: true,
      },
      align: {
        type: String,
        default: '',
      },
      hidden: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {
      currentPage: {
        get() {
          return this.page
        },
        set(val) {
          this.$emit('update:page', val)
        },
      },
      pageSize: {
        get() {
          return this.limit
        },
        set(val) {
          this.$emit('update:limit', val)
        },
      },
    },
    methods: {
      handleSizeChange(val) {
        this.$emit('pagination', { page: 1, limit: val })
        // this.$emit('pagination', { page: this.currentPage, limit: val })
        // if (this.autoScroll) {
        //   scrollTo(0, 800)
        // }
      },
      handleCurrentChange(val) {
        this.$emit('pagination', { page: val, limit: this.pageSize })
        // if (this.autoScroll) {
        //   scrollTo(0, 800)
        // }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pagination-container {
    background-color: #fff;
    padding: 20px 10px;
    text-align: right;
    padding-right: 60px;
  }
  .pagination-container.hidden {
    display: none;
  }
  .pagination-container.left {
    text-align: left;
  }
</style>
