<
<template>
  <el-dialog
    :title="$t(title)"
    :visible.sync="dialogFormVisible"
    width="40%"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <!-- <el-form-item label="评价类型" prop="evaluationType"> -->
            <el-form-item
              :label="$t('cusDetail.EvaluationType')"
              prop="evaluationType"
            >
              <el-radio-group v-model="form.evaluationType">
                <!-- <el-radio :label="0">好评</el-radio>
                <el-radio :label="1">投诉</el-radio> -->
                <el-radio :label="0">{{ $t('cusDetail.Praise') }}</el-radio>
                <el-radio :label="1">{{ $t('cusDetail.Complain') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- label="评价来源" -->
            <el-form-item
              :label="$t('cusDetail.EvaluationSources')"
              prop="evaluationSource"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  message: $t('placeholder.plsInput'),
                },
              ]"
            >
              <el-input
                v-model.trim="form.evaluationSource"
                :placeholder="$t('placeholder.plsInput')"
                maxlength="50"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- label="评价人" -->
            <el-form-item
              :label="$t('cusDetail.Evaluator')"
              prop="evaluator"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  message: $t('placeholder.plsInput'),
                },
              ]"
            >
              <el-input
                v-model.trim="form.evaluator"
                maxlength="50"
                :placeholder="$t('placeholder.plsInput')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- label="评价等级" -->
            <el-form-item
              :label="$t('cusDetail.EvaluationLevel')"
              prop="evaluationLevel"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  message: $t('placeholder.plsInput'),
                },
              ]"
            >
              <el-input
                v-model.trim="form.evaluationLevel"
                maxlength="50"
                :placeholder="$t('placeholder.plsInput')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- label="评价日期" -->
            <el-form-item
              :label="$t('cusDetail.EvaluationDate')"
              prop="evaluationDate"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <el-date-picker
                style="width: 100%"
                v-model="form.evaluationDate"
                type="date"
                :placeholder="$t('placeholder.selDate')"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- label="关联订单" -->
            <el-form-item
              :label="$t('cusDetail.AssociatedOrder')"
              prop="associatedOrder"
            >
              <el-input
                v-model.trim="form.associatedOrder"
                :placeholder="$t('placeholder.plsInput')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- label="关联客户代表" -->
            <el-form-item
              :label="$t('cusDetail.AssociatedCustomerRepresentative')"
              :rules="{
                required: form.evaluationType === 1 ? true : false,
                trigger: 'change',
                message: '请选择客户代表',
              }"
              prop="associatedSalesman"
            >
              <el-input
                v-model.trim="form.associatedSalesman"
                @focus="selectUserClick('businessName')"
                clearable
                @change="businessNameChange"
                :placeholder="$t('placeholder.plsSel')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- label="评价内容" -->
            <el-form-item
              :label="$t('cusDetail.EvaluationContent')"
              prop="evaluationContent"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  message: $t('placeholder.plsInput'),
                },
              ]"
            >
              <el-input
                type="textarea"
                v-model.trim="form.evaluationContent"
                :autosize="{ minRows: 2, maxRows: 4 }"
                maxlength="1000"
                show-word-limit
                :placeholder="$t('placeholder.plsInput')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- label="图片" -->
            <el-form-item :label="$t('cusDetail.Image')">
              <el-row class="el-row-upload">
                <!-- 支持格式：jpg/jpeg/png，最多上传九张，单个文件大小不允许超过2MB -->
                <erp-upload-images
                  :allowedType="allowedType"
                  :limit="9"
                  :tips-visible="false"
                  :tip-text="$t('cusDetail.SupportedFormatsTips')"
                  :default-file-list="defaultFileList4"
                  @change="changeUploadList4"
                ></erp-upload-images>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 人员组织架构树 -->
      <UserChoose ref="UserChoose" @choose-user="chooseUser" />
    </div>
    <template #footer>
      <el-row class="text-center">
        <!-- <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button> -->
        <el-button type="primary" :loading="loading" @click="save">
          {{ $t('cusDetail.Confirm') }}
        </el-button>
        <el-button @click="close">{{ $t('cusDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { evaluationRecordsInsertPO } from '@/api/client-supplier/client-manage'
  import UserChoose from '@/components/userChoose2'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ClinetAppraiseModel',
    components: { UserChoose },
    data() {
      return {
        loading: false,
        // title: '添加客户评价',
        title: 'cusDetail.AddCustomerReviews',
        form: {
          infoId: '', //客户id
          evaluationType: 0, //评价类型（0好评，1投诉）
          evaluationSource: '', //评价来源
          evaluator: '', //评价人
          associatedSalesman: '', //关联客户代表
          evaluationLevel: '', //评价等级
          evaluationDate: '', //评价日期
          associatedOrder: '', //关联订单
          evaluationContent: '', //评价内容
          evaluationPicture: '', //评价照片
          status: '', //好评不传,差评传 0, 提交反馈时传 1
        },
        associatedSalesmanId: [], //关联客户代表-id
        rules: {
          // evaluationSource: [
          //   { required: true, trigger: 'blur', message: '请输入评价来源' },
          // ],
          // evaluator: [
          //   { required: true, trigger: 'blur', message: '请输入评价人' },
          // ],
          // evaluationLevel: [
          //   { required: true, trigger: 'blur', message: '请输入评价等级' },
          // ],
          // evaluationDate: [
          //   { required: true, trigger: 'change', message: '请选择评价日期' },
          // ],
          // evaluationContent: [
          //   { required: true, trigger: 'blur', message: '请输入评价内容' },
          // ],
        },
        dialogFormVisible: false,
        allowedType: 'jpg、 png、jpeg',
        defaultFileList4: [], //评价照片
        enterprisePhoto: [],
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    created() {},
    methods: {
      //新增
      showAddEdit(customerId) {
        this.form.infoId = customerId
        this.dialogFormVisible = true
      },

      //关闭
      close() {
        this.associatedSalesmanId = []
        this.checkedUser = []
        this.enterprisePhoto = []
        this.defaultFileList4 = []
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
      },

      //评价照片
      changeUploadList4(list) {
        if (list.length) {
          this.enterprisePhoto = []
          list.forEach((item) => {
            this.enterprisePhoto.push(item.url)
          })
          this.form.evaluationPicture = this.enterprisePhoto.join(',')
        } else {
          this.enterprisePhoto = []
          this.form.evaluationPicture = ''
        }
      },

      //客户代表选择
      selectUserClick() {
        this.$refs['UserChoose'].showAddEdit(
          'listSearch',
          this.associatedSalesmanId,
          true
        )
      },
      //客户代表返回
      chooseUser(checkedUser) {
        this.businessNameChange()
        if (!checkedUser.length) {
          return
        }
        let userName = []
        this.checkedUser = checkedUser
        checkedUser.forEach((item) => {
          userName.push(this.lang !== 'en' ? item.name : item.englishName)
          this.associatedSalesmanId.push(item.id)
          this.form.associatedSalesman = userName.join(',')
          this.form.associatedSalesmanId = this.associatedSalesmanId.join(',')
        })
      },
      // 删除客户代表
      businessNameChange() {
        this.associatedSalesmanId = []
        this.form.associatedSalesman = ''
        this.form.associatedSalesmanEn = ''
        this.form.associatedSalesmanId = ''
      },

      //保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let form = this.utils.deepCopy(this.form)
            form.associatedSalesman = this.checkedUser
              ?.map((i) => i.name)
              .join(',')
            if (form.evaluationType === 1) {
              form.status = 0
            }
            this.loading = true
            let response = await evaluationRecordsInsertPO(form)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                // '新增成功',
                this.$t('reqmsg.M2016'),
                'success',
                false,
                'erp-hey-message-success'
              )
            }
            this.$Bus.$emit('appraiseRefresh')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  .text-hidden {
    width: 150px;
  }
</style>
