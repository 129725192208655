<template>
  <!-- title="查看客户评价" -->
  <el-dialog
    :title="$t('dialogTitle.Viewcustomerreviews')"
    :visible.sync="dialogFormVisible"
    width="45%"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-row type="flex" align="middle" :gutter="20">
        <el-col :span="8">
          <el-row>
            <!-- <span>评价类型</span> -->
            <span>{{ $t('cusDetail.EvaluationType') }}</span>
            <p>
              <!-- {{
                appraiseDetail.evaluationType === 0 ? '好评' : '投诉' || '--'
              }} -->
              {{
                appraiseDetail.evaluationType === 0
                  ? $t('other.Praise')
                  : $t('other.complaint') || '--'
              }}
              <el-tag
                v-if="appraiseDetail.evaluationType === 1"
                class="ml5"
                :type="appraiseDetail.status === 0 ? 'info' : 'success'"
              >
                <!-- {{ appraiseDetail.status === 0 ? '待处理' : '已反馈' }} -->
                {{
                  appraiseDetail.evaluationType === 0
                    ? $t('other.Tobeprocessed')
                    : $t('other.Havefeedback') || '--'
                }}
              </el-tag>
            </p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <!-- <span>评价来源</span> -->
            <span>{{ $t('cusDetail.EvaluationSources') }}</span>
            <p>{{ appraiseDetail.evaluationSource || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <!-- <span>评价人</span> -->
            <span>{{ $t('cusDetail.Evaluator') }}</span>
            <p>{{ appraiseDetail.evaluator || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <!-- <span>评价等级</span> -->
            <span>{{ $t('cusDetail.EvaluationLevel') }}</span>
            <p>{{ appraiseDetail.evaluationLevel || '--' }}</p>
          </el-row>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" :gutter="20" class="mt20">
        <el-col :span="8">
          <el-row>
            <!-- <span>评价日期</span> -->
            <span>{{ $t('cusDetail.EvaluationDate') }}</span>
            <p>{{ appraiseDetail.evaluationDate || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <!-- <span>关联订单</span> -->
            <span>{{ $t('cusDetail.AssociatedOrder') }}</span>
            <p
              v-if="appraiseDetail.associatedOrder"
              @click="purchaseOrderNumberClick(appraiseDetail.associatedOrder)"
              class="blue-text"
            >
              {{ appraiseDetail.associatedOrder || '--' }}
            </p>
            <p v-else>--</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <!-- <span>关联客户代表</span> -->
            <span>{{ $t('cusDetail.AssociatedCustomerRepresentative') }}</span>
            <p>
              <!-- {{ appraiseDetail.associatedSalesman || '--' }} -->
              {{ getName(appraiseDetail, 'associatedSalesman') || '--' }}
            </p>
          </el-row>
        </el-col>
        <el-col :span="8"></el-col>
      </el-row>
      <el-row class="mt20">
        <!-- <span>评价内容</span> -->
        <span>{{ $t('cusDetail.EvaluationContent') }}</span>
        <p>
          {{ appraiseDetail.evaluationContent || '--' }}
        </p>
      </el-row>
      <el-row type="flex" class="mt20" :gutter="20">
        <el-col :span="12">
          <el-row>
            <!-- <span>图片</span> -->
            <span>{{ $t('cusDetail.Image') }}</span>
            <el-row
              class="mt10 pic-box"
              type="flex"
              align="middle"
              v-if="appraiseDetail.evaluationPicture"
            >
              <el-image
                :src="appraiseDetail.evaluationPicture.split(',')[0]"
                class="c_pointer"
                style="width: 100px; height: 100px"
                @click="
                  handlePreview(appraiseDetail.evaluationPicture.split(','))
                "
              ></el-image>
              <span
                class="fileAnnex-span"
                v-if="appraiseDetail.evaluationPicture.split(',').length > 1"
              >
                {{ appraiseDetail.evaluationPicture.split(',').length }}
              </span>
            </el-row>
            <p v-else>--</p>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <!-- <span>创建人</span> -->
            <span>{{ $t('cusDetail.Creator') }}</span>
            <!-- <p>{{ appraiseDetail.createName || '--' }}</p> -->
            <p>{{ getName(appraiseDetail, 'createName') || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <!-- <span>创建时间</span> -->
            <span>{{ $t('cusDetail.CreationTime') }}</span>
            <p>{{ appraiseDetail.createTime || '--' }}</p>
          </el-row>
        </el-col>
      </el-row>

      <!-- 反馈内容 -->
      <el-row class="mt20 floor" v-if="appraiseDetail.feedbackContent">
        <!-- <span>反馈内容</span> -->
        <span>{{ $t('other.Contentofthefeedback') }}</span>
        <p>
          {{ appraiseDetail.feedbackContent || '--' }}
        </p>
      </el-row>
      <el-row
        type="flex"
        class="mt20"
        :gutter="20"
        v-if="appraiseDetail.feedbackPerson"
      >
        <el-col :span="12">
          <el-row>
            <!-- <span>图片</span> -->
            <span>{{ $t('cusDetail.Image') }}</span>
            <el-row
              class="mt10 pic-box"
              type="flex"
              align="middle"
              v-if="appraiseDetail.feedbackPicture"
            >
              <el-image
                :src="appraiseDetail.feedbackPicture.split(',')[0]"
                class="c_pointer"
                style="width: 100px; height: 100px"
                @click="
                  handlePreview(appraiseDetail.feedbackPicture.split(','))
                "
              ></el-image>
              <span
                class="fileAnnex-span"
                v-if="appraiseDetail.feedbackPicture.split(',').length > 1"
              >
                {{ appraiseDetail.feedbackPicture.split(',').length }}
              </span>
            </el-row>
            <p v-else>--</p>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <!-- <span>反馈人</span> -->
            <span>{{ $t('other.Feedbackpeople') }}</span>
            <p>{{ getName(appraiseDetail,'feedbackPerson') || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <span>{{ $t('other.Feedbacktime') }}</span>
            <p>{{ appraiseDetail.feedbackTime || '--' }}</p>
          </el-row>
        </el-col>
      </el-row>

      <!-- 图片预览 -->
      <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="imgUrl"
      />
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">
          <!-- 关闭 -->
          {{ $t('other.close') }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { judgeDetailForShow } from '@/api/client-supplier/client-manage'
import { paymentStatusVO } from '@/api/finance/finance-payment'
import nameMixin from '@/utils/name-mixin'

export default {
  name: 'AppraiseDetailModel',
  mixins: [nameMixin],
  data() {
    return {
      dialogFormVisible: false,
      appraiseDetail: {}, //评价详情
      showViewer: false, //图片预览参数
      imgUrl: [], //图片预览url
    }
  },
  methods: {
    //新增
    showAddEdit(evaluationRecordsId) {
      this.dialogFormVisible = true
      this.getJudgeDetailForShow(evaluationRecordsId)
    },

    //评价详情
    async getJudgeDetailForShow(id) {
      let response = await judgeDetailForShow({ evaluationRecordsId: id })
      if (response.code === '000000') {
        this.appraiseDetail = response.data
      }
    },

    //订单跳转
    //销售订单号跳转
    async purchaseOrderNumberClick(code) {
      let response = await paymentStatusVO({
        orderCode: code,
      })
      if (response.code == '000000') {
        let orderStatus = response.data.orderStatus
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: { orderCode: code, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      }
    },

    //图片预览
    // 大图预览
    handlePreview(item) {
      if (Array.isArray(item)) {
        this.imgUrl = item
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },

    //关闭
    close() {
      this.dialogFormVisible = false
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  padding: 0 15px;
  max-height: 60vh;
  overflow: hidden;
  overflow-y: auto;
  .el-row {
    span {
      color: #666;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      color: #000;
    }
  }
  .floor {
    border-top: 1px solid #ededed;
    padding-top: 20px;
  }
  .pic-box {
    img {
      width: 100px;
      height: 100px;
    }
  }
}
.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff !important;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
}
.blue-text {
  text-decoration: underline;
  color: #1890ff !important;
}
</style>
