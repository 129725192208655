<template>
  <el-row class="client-msg" type="flex" :gutter="20">
    <!-- 客户信息-左侧 -->
    <el-col :span="7">
      <el-row class="client-left">
        <!-- top -->
        <el-row class="client-left-floor client-left-first">
          <el-row type="flex" align="middle" justify="space-between">
            <el-tooltip
              class="item h6-tooltip"
              effect="dark"
              :content="customerInfo.email"
              placement="top"
            >
              <h6 class="f_s_18">{{ customerInfo.email || '--' }}</h6>
            </el-tooltip>

            <el-button @click="editClientClick(customerId)" v-if="!isBlacklist">
              <!-- 编辑客户资料 -->
              {{ $t('cusDetail.EditCustomerProfile') }}
            </el-button>
          </el-row>
          <el-row type="flex" align="middle" class="split-50-row">
            <el-row>
              <span class="d_block">
                <!-- 客户姓名 -->
                {{ $t('cusDetail.CustomerName') }}
              </span>
              <span class="d_block">
                <!-- {{ $t('cusDetail.EditCustomerProfile') }} -->
              </span>
              <p class="f_s_16 f_w_6 mt10">
                {{ customerInfo.customerName || '--' }}
              </p>
            </el-row>
            <el-row>
              <!-- <span class="d_block">公司名称</span> -->
              <span class="d_block">
                {{ $t('cusDetail.CompanyName') }}
              </span>

              <p class="f_s_16 f_w_6 mt10">
                {{ customerInfo.companyName | lengthLimit }}
              </p>
            </el-row>
          </el-row>
          <!-- <el-row>
            客户查看订单权限
            <span class="u160 c_pointer">待分配</span>
          </el-row> -->
        </el-row>
        <!-- middle -->
        <el-row
          class="client-left-floor client-left-second"
          type="flex"
          align="middle"
          justify="space-between"
        >
          <!-- <el-row>
            <el-row>
              <span v-if="customerInfo.allocatedMoney">
                {{ customerInfo.allocatedMoney }}
              </span>
              <span v-else class="no-assign">0</span>
              <p>待分配金额</p>
            </el-row>
            <el-button
              @click="
                toBeAssignClick(customerInfo.infoId, customerInfo.companyName)
              "
            >
              查看
            </el-button>
          </el-row> -->
          <el-row>
            <el-row>
              <!-- <span>{{ customerInfo.belongerName }}</span> -->
              <span>{{ getName(customerInfo, 'belongerName') }}</span>
              <!-- <p>负责人</p> -->
              <p>{{ $t('cusDetail.ResponsiblePerson') }}</p>
            </el-row>
            <el-button
              @click="leadingHistoryClick(customerInfo.infoId, 'leading')"
            >
              <!-- 查看记录 -->
              {{ $t('cusDetail.ViewRecords') }}
            </el-button>
          </el-row>
          <el-row>
            <el-row>
              <el-popover
                v-if="customerInfo.managers"
                placement="top-start"
                trigger="hover"
                :content="
                  lang === 'en'
                    ? customerInfo.managersEn
                    : customerInfo.managers
                "
              >
                <span slot="reference" class="person-span">
                  <!-- {{ customerInfo.managers }} -->
                  {{ getName(customerInfo, 'managers') }}
                </span>
              </el-popover>
              <!-- <span v-else class="no-assign">无</span> -->
              <span v-else class="no-assign">{{ $t('other.no') }}</span>
              <!-- <p>分管人</p> -->
              <p>{{ $t('cusDetail.PersonInCharge') }}</p>
            </el-row>
            <el-button
              @click="leadingHistoryClick(customerInfo.infoId, 'assigned')"
            >
              <!-- 查看记录 -->
              {{ $t('cusDetail.ViewRecords') }}
            </el-button>
          </el-row>
        </el-row>
        <!-- bottom -->
        <el-row class="client-left-floor client-left-three">
          <el-row>
            <!-- <span>公司地址</span> -->
            <span>{{ $t('cusDetail.CompanyAddress') }}</span>
            <p v-if="customerInfo.companyAddress">
              {{ customerInfo.companyAddress }}
            </p>
            <!-- <p v-else class="no-text">未填写</p> -->
            <p v-else class="no-text">{{ $t('other.noWrite') }}</p>
          </el-row>
          <el-row>
            <!-- <span>客户来源</span> -->
            <span>{{ $t('cusDetail.CustomerSource') }}</span>
            <p v-if="customerInfo.customerSourceName">
              {{ getName(customerInfo, 'customerSourceName') }}
            </p>
            <!-- <p v-else class="no-text">未填写</p> -->
            <p v-else class="no-text">{{ $t('other.noWrite') }}</p>
          </el-row>
          <el-row>
            <!-- <span>客户级别</span> -->
            <span>{{ $t('cusDetail.CustomerLevel') }}</span>
            <el-rate
              class="mt10"
              v-if="customerInfo.customerRank"
              v-model="customerInfo.customerRank"
              disabled
              text-color="#ff9900"
            ></el-rate>
            <!-- <p v-else class="no-text">未填写</p> -->
            <p v-else class="no-text">{{ $t('other.noWrite') }}</p>
          </el-row>
          <el-row>
            <!-- <span>客户类型</span> -->
            <span>{{ $t('cusDetail.CustomerType') }}</span>
            <p>
              {{ $t(customerTypeStatus(customerInfo.customerType)) }}
            </p>
          </el-row>
          <el-row>
            <!-- <span>邮编</span> -->
            <span>{{ $t('cusDetail.ZipCode') }}</span>
            <p v-if="customerInfo.postcode">{{ customerInfo.postcode }}</p>
            <!-- <p v-else class="no-text">未填写</p> -->
            <p v-else class="no-text">{{ $t('other.noWrite') }}</p>
          </el-row>
          <el-row>
            <!-- <span>网址</span> -->
            <span>{{ $t('cusDetail.WebAddress') }}</span>
            <p v-if="customerInfo.website">{{ customerInfo.website }}</p>
            <!-- <p v-else class="no-text">未填写</p> -->
            <p v-else class="no-text">{{ $t('other.noWrite') }}</p>
          </el-row>
          <el-row>
            <!-- <span>传真</span> -->
            <span>{{ $t('cusDetail.FaxNumber') }}</span>
            <p v-if="customerInfo.faxNumber">{{ customerInfo.faxNumber }}</p>
            <!-- <p v-else class="no-text">未填写</p> -->
            <p v-else class="no-text">{{ $t('other.noWrite') }}</p>
          </el-row>
          <el-row>
            <!-- <span>备注</span> -->
            <span>{{ $t('cusDetail.Remark') }}</span>
            <p v-if="customerInfo.remark">{{ customerInfo.remark }}</p>
            <!-- <p v-else class="no-text">未填写</p> -->
            <p v-else class="no-text">{{ $t('other.noWrite') }}</p>
          </el-row>
        </el-row>
      </el-row>
    </el-col>
    <!-- 客户信息-右侧 -->
    <el-col :span="17">
      <el-row class="clent-right">
        <!-- 联系人信息 -->
        <el-row class="floor">
          <!-- <p class="p-title">联系人信息</p> -->
          <p class="p-title">{{ $t('cusDetail.ContactInformation') }}</p>
          <el-table :data="customerInfo.linkmans" style="width: 100%" border>
            <!-- label="序号" -->
            <el-table-column
              type="index"
              :label="$t('cusDetail.SerialNumber')"
              width="60"
              align="center"
            ></el-table-column>
            <!-- label="联系人" -->

            <el-table-column
              prop="linkman"
              :label="$t('cusDetail.Contacts')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- label="邮箱" -->
            <el-table-column
              prop="email"
              :label="$t('cusDetail.Mail')"
              align="center"
              show-overflow-tooltip
              width="200"
            >
              <template slot-scope="scope">
                <span
                  class="c_pointer prod-code"
                  @click="emailClick(scope.row.email)"
                >
                  {{ scope.row.email }}
                </span>
              </template>
            </el-table-column>
            <!-- label="联系电话" -->
            <el-table-column
              prop="phone"
              :label="$t('cusDetail.ContactNumber')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.phone || '--' }}
              </template>
            </el-table-column>
            <!-- label="WhatsApp" -->
            <el-table-column
              prop="whatsapp"
              :label="$t('cusDetail.WhatsApp')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.whatsapp || '--' }}
              </template>
            </el-table-column>
            <!-- label="Skype" -->
            <el-table-column
              prop="skype"
              :label="$t('cusDetail.Skype')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.whatsapp || '--' }}
              </template>
            </el-table-column>
            <!-- label="wechat" -->
            <el-table-column
              prop="Wechat"
              :label="$t('cusDetail.Wechat')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.whatsapp || '--' }}
              </template>
            </el-table-column>
            <!-- label="是否主要" -->
            <el-table-column
              prop="major"
              align="center"
              :label="$t('cusDetail.WhetherTheMain')"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.major"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
                ></el-switch>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- 付款方式 -->
        <el-row class="floor">
          <!-- <p class="p-title">付款方式</p> -->
          <p class="p-title">{{ $t('cusDetail.PaymentMethod') }}</p>
          <el-table :data="customerInfo.pays" style="width: 50%" border>
            <el-table-column
              type="index"
              label="#"
              width="60"
              align="center"
            ></el-table-column>
            <!-- label="金额区间" -->
            <el-table-column
              prop="payway"
              :label="$t('cusDetail.AmountRange')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <!-- {{ scope.row.payway.split('/')[0] }} -->
                {{
                  lang == 'en'
                    ? scope.row.payway.split('/')[0] &&
                      scope.row.payway.split('/')[0].replace('全部', 'All')
                    : scope.row.payway.split('/')[0]
                }}
              </template>
            </el-table-column>
            <!-- label="付款方式" -->

            <el-table-column
              prop="payway"
              :label="$t('cusDetail.PaymentMethod')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.payway.split('/')[1] }}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- 收货地址 -->
        <el-row class="floor">
          <!-- <p class="p-title">收货地址</p> -->
          <p class="p-title">{{ $t('cusDetail.ShippingAddress') }}</p>
          <el-table
            :data="customerInfo.addressConfigVOS"
            style="width: 100%"
            border
          >
            <el-table-column
              type="index"
              label="#"
              width="60"
              align="center"
            ></el-table-column>
            <!-- label="收货地址" -->
            <el-table-column
              prop="address"
              :label="$t('cusDetail.ShippingAddress')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- label="收货公司"/ -->
            <el-table-column
              prop="companyName"
              :label="$t('cusDetail.ReceivingCompany')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- label="收货人" -->
            <el-table-column
              prop="consigneeName"
              :label="$t('cusDetail.Receiver')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- label="联系电话" -->
            <el-table-column
              prop="consigneePhone"
              :label="$t('cusDetail.ContactNumber')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- label="是否默认" -->
            <el-table-column
              prop="defaultAdress"
              :label="$t('cusDetail.Default')"
              align="center"
            >
              <template slot-scope="scope">
                <!-- {{ scope.row.defaultAdress === 1 ? '是' : '否' }} -->
                {{
                  scope.row.defaultAdress === 1
                    ? $t('other.yes')
                    : $t('other.no')
                }}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- 跟进记录 -->
        <el-row class="floor">
          <!-- <p class="p-title">跟进记录</p> -->
          <p class="p-title">{{ $t('cusDetail.FollowUpRecord') }}</p>
          <el-table
            :data="customerInfo.follows"
            style="width: 100%"
            border
            class="follows-table"
          >
            <el-table-column
              type="index"
              label="#"
              width="60"
              align="center"
            ></el-table-column>
            <!-- label="联系人" -->
            <el-table-column
              prop="linkman"
              :label="$t('cusDetail.Contacts')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- label="跟进方式" -->
            <el-table-column
              prop="linkType"
              :label="$t('cusDetail.FollowUpWay')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- label="跟进主题" -->
            <el-table-column
              prop="theme"
              :label="$t('cusDetail.FollowUpTopic')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.theme || '--' }}
              </template>
            </el-table-column>
            <!-- label="跟进方向" -->
            <el-table-column
              prop="direction"
              :label="$t('cusDetail.FollowUpDirection')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  scope.row.direction === '进'
                    ? $t('other.in')
                    : $t('other.out')
                }}
              </template>
            </el-table-column>
            <!-- label="跟进目的" -->
            <el-table-column
              prop="followGoal"
              :label="$t('cusDetail.FollowUpPurpose')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- label="跟进时间" -->
            <el-table-column
              prop="followTime"
              :label="$t('cusDetail.FollowUpTime')"
              align="center"
            ></el-table-column>
            <!-- label="订单编号" -->
            <el-table-column
              prop="serial"
              align="center"
              :label="$t('cusDetail.OrderNumber')"
            >
              <template slot-scope="scope">
                {{ scope.row.serial || '--' }}
              </template>
            </el-table-column>
            <!-- label="创建人" -->
            <el-table-column
              prop="creatorName"
              :label="$t('cusDetail.Creator')"
              align="center"
            >
              <template slot-scope="scope">
                {{ getName(scope.row, 'creatorName') }}
              </template>
            </el-table-column>
            <!-- label="创建时间" -->
            <el-table-column
              prop="createTime"
              :label="$t('cusDetail.CreationTime')"
              align="center"
            ></el-table-column>
            <!-- label="操作" -->
            <el-table-column align="center" :label="$t('cusDetail.Operate')">
              <template slot-scope="scope">
                <!-- content=" 查看" -->
                <el-tooltip placement="top" :content="$t('iconbtn.check')">
                  <el-button
                    type="primary"
                    plain
                    :icon="'el-icon-search'"
                    circle
                    size="mini"
                    @click="followShowClick(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- 评价记录 -->
        <el-row class="floor roll-box">
          <!-- <p class="p-title">评价记录</p> -->
          <p class="p-title">{{ $t('cusDetail.EvaluationRecord') }}</p>
          <el-table
            v-loading="appraiseLoading"
            :data="appraiseData"
            border
            ref="table"
            height="200"
            class="targetDom"
          >
            <el-table-column
              type="index"
              label="#"
              width="60"
              align="center"
            ></el-table-column>
            <!-- label="类型" -->
            <el-table-column
              prop="evaluationType"
              :label="$t('cusDetail.Type')"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <!-- {{ scope.row.evaluationType === 0 ? '好评' : '投诉' || '--' }} -->
                {{
                  scope.row.evaluationType === 0
                    ? $t('other.Praise')
                    : $t('other.complaint') || '--'
                }}
                <el-tag
                  v-if="scope.row.evaluationType === 1"
                  size="mini"
                  class="ml5"
                  :type="scope.row.status === 0 ? 'info' : 'success'"
                >
                  <!-- {{ scope.row.status === 0 ? '待处理' : '已反馈' }} -->
                  {{
                    scope.row.evaluationType === 0
                      ? $t('other.Tobeprocessed')
                      : $t('other.Havefeedback') || '--'
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <!-- label="来源" -->
            <el-table-column
              prop="evaluationSource"
              :label="$t('cusDetail.Source')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.evaluationSource || '--' }}
              </template>
            </el-table-column>
            <!-- label="评价人" -->
            <el-table-column
              prop="evaluator"
              :label="$t('cusDetail.Evaluator')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.evaluator || '--' }}
              </template>
            </el-table-column>
            <!-- label="评价等级" -->
            <el-table-column
              prop="evaluationLevel"
              :label="$t('cusDetail.EvaluationLevel')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.evaluationLevel || '--' }}
              </template>
            </el-table-column>
            <!-- label="评价日期" -->
            <el-table-column
              prop="evaluationDate"
              :label="$t('cusDetail.EvaluationDate')"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.evaluationDate || '--' }}
              </template>
            </el-table-column>
            <!-- label="关联订单" -->
            <el-table-column
              prop="associatedOrder"
              :label="$t('cusDetail.AssociatedOrder')"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.associatedOrder || '--' }}
              </template>
            </el-table-column>
            <!-- label="关联客户代表" -->
            <el-table-column
              prop="associatedSalesman"
              :label="$t('cusDetail.AssociatedCustomerRepresentative')"
              align="center"
            >
              <template slot-scope="scope">
                <!-- {{ scope.row.associatedSalesman || '--' }} -->

                {{ getName(scope.row, 'associatedSalesman') || '--' }}
              </template>
            </el-table-column>
            <!-- label="创建人" -->
            <el-table-column
              prop="createName"
              align="center"
              :label="$t('cusDetail.Creator')"
            >
              <template slot-scope="scope">
                <!-- {{ scope.row.createName || '--' }} -->
                {{ getName(scope.row, 'createName') || '--' }}
              </template>
            </el-table-column>
            <!-- label="创建时间" -->
            <el-table-column
              prop="createTime"
              :label="$t('cusDetail.CreationTime')"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.createTime || '--' }}
              </template>
            </el-table-column>
            <!-- label="操作" -->
            <el-table-column
              :label="$t('cusDetail.Operate')"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <!-- content="反馈" -->
                <el-tooltip placement="top" :content="$t('iconbtn.feedback')">
                  <el-button
                    v-if="
                      scope.row.status === 0 &&
                      userInfo.userId === scope.row.associatedSalesmanId
                    "
                    type="primary"
                    plain
                    circle
                    size="mini"
                    @click="feedbackClick(scope.row.evaluationRecordsId)"
                  >
                    <i class="iconfont icon-fankui"></i>
                  </el-button>
                </el-tooltip>
                <!-- content="查看" -->
                <el-tooltip placement="top" :content="$t('iconbtn.check')">
                  <el-button
                    type="primary"
                    plain
                    :icon="'el-icon-search'"
                    circle
                    size="mini"
                    @click="lookAppraiseClick(scope.row.evaluationRecordsId)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-row>
    </el-col>

    <!-- 提交反馈 -->
    <FeedbackModel
      ref="FeedbackModel"
      @feed-back="workGetEvaluationRecordslistPage"
    />
    <!-- 评价详情 -->
    <AppraiseDetailModel ref="AppraiseDetailModel" />
    <!-- 跟进记录详情 -->
    <FollowDetailModel ref="FollowDetailModel" />

    <!-- 待分配金额 -->
    <ToBeAssignModel ref="ToBeAssignModel" />

    <!-- 查看历史 -->
    <el-drawer
      :title="historyTitle"
      :visible.sync="historyDrawer"
      :before-="historyClose"
      v-if="historyDrawer"
    >
      <LeadingHistoryDrawer
        ref="LeadingHistoryDrawer"
        :infoId="infoId"
        :historyStr="historyStr"
      />
    </el-drawer>
  </el-row>
</template>

<script>
  import {
    getDetailForEditShow,
    evaluationRecordslistPage,
  } from '@/api/client-supplier/client-manage'
  import FeedbackModel from './feedback-model'
  import AppraiseDetailModel from './appraise-detail-model'
  import ToBeAssignModel from './to-be-assign-model'
  import LeadingHistoryDrawer from './leading-history-drawer'
  import FollowDetailModel from './follow-detail-model'
  import { customerTypeStatusLang } from '@/utils/en-match-zh'
  import { mapGetters } from 'vuex'
  import nameMixin from '@/utils/name-mixin'
  export default {
    name: 'ClientManageDetailMag2',
    mixins: [nameMixin],
    components: {
      FeedbackModel,
      AppraiseDetailModel,
      LeadingHistoryDrawer,
      FollowDetailModel,
      ToBeAssignModel,
    },

    props: {
      customerId: {
        type: String,
        required: true,
      },
      removeAppraise: {
        type: Boolean,
        default: false,
      },
      isBlacklist: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        infoId: '', //客户id
        customerInfo: {}, //客户详情信息
        historyDrawer: false, //查看负责人历史
        historyStr: '', //当前是分管人、负责人
        historyTitle: '', //分管，负责抽屉的title
        appraiseForm: { pageNo: 1, pageLe: 5 },
        appraiseTotal: 0, //评价记录总数
        appraiseData: [], //客户评价记录
        appraiseLoading: false, //评价记录loading
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
    },

    watch: {
      removeAppraise: {
        immediate: true,
        deep: true,
        handler(value) {
          if (value) {
            this.getDetailForEditShow()
          }
        },
      },
    },

    created() {
      if (this.customerId) {
        this.getDetailForEditShow() //客户信息详情
        this.getEvaluationRecordslistPage() //客户评价记录
      }
      //添加客户评价确认触发
      this.$Bus.$on('appraiseRefresh', () => {
        this.appraiseForm.pageNo = 1
        this.appraiseData = []
        this.getEvaluationRecordslistPage() //客户评价记录
      })
    },

    mounted() {
      // 监听评价记录滚动
      let dom = document.querySelector('.roll-box .el-table__body-wrapper')
      dom.addEventListener('scroll', (v) => {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight
        if (scrollDistance <= 0) {
          if (this.appraiseData.length >= this.totalPage) {
            this.appraiseLoading = false
            return
          }
          if (this.appraiseData.length < this.totalPage) {
            this.appraiseForm.pageNo++
            this.getEvaluationRecordslistPage()
          }
        }
      })
    },
    filters: {
      lengthLimit(val) {
        if (val?.length > 100) {
          return val.substring(0, 100) + '...'
        } else {
          return val ? val : '--'
        }
      },
    },

    methods: {
      //客户详情
      async getDetailForEditShow() {
        let response = await getDetailForEditShow({ infoId: this.customerId })
        if (response?.code === '000000') {
          this.customerInfo = response.data
        }
      },

      //客户评价记录
      async getEvaluationRecordslistPage() {
        this.loading = true
        let response = await evaluationRecordslistPage({
          infoId: this.customerId,
          ...this.appraiseForm,
        })
        if (response?.code === '000000') {
          this.appraiseData = this.appraiseData.concat(response.data.data)
          this.totalPage = response.data.total
          this.appraiseLoading = false
        }
      },

      //反馈刷新评价记录
      workGetEvaluationRecordslistPage() {
        this.appraiseForm.pageNo = 1
        this.appraiseData = []
        this.getEvaluationRecordslistPage() //客户评价记录
      },

      //编辑客户
      editClientClick(infoId) {
        this.$router.push({
          path: '/client-supplier/client-manage-detail',
          query: { customerId: infoId, operate: 'edit' },
        })
      },

      //跟进记录查看
      followShowClick(row) {
        this.$refs['FollowDetailModel'].showAddEdit(row)
      },

      //负责人，分管人历史记录
      leadingHistoryClick(infoId, str) {
        this.historyStr = ''
        this.infoId = ''
        str === 'leading'
          ? // ? (this.historyTitle = '负责人历史记录')
            // : (this.historyTitle = '分管历史记录')
            (this.historyTitle = this.$t('other.Historyofpersonincharge'))
          : (this.historyTitle = this.$t('other.Responsiblehistory'))
        this.historyStr = str
        this.infoId = infoId

        this.historyDrawer = true
      },
      //负责人，分管人历史记录-关闭
      historyClose() {
        this.historyDrawer = false
      },

      //邮件跳转
      emailClick(email) {
        let routeData = this.$router.resolve({
          name: 'MailReceiveAll',
          query: { searchValue: email },
        })
        window.open(routeData.href, '_blank')
      },

      //提交反馈
      feedbackClick(evaluationRecordsId) {
        this.$refs['FeedbackModel'].showAddEdit(evaluationRecordsId)
      },

      //查看评价详情
      lookAppraiseClick(evaluationRecordsId) {
        this.$refs['AppraiseDetailModel'].showAddEdit(evaluationRecordsId)
      },
      //客户类型匹配
      customerTypeStatus(val) {
        return customerTypeStatusLang(val)
      },

      //待分配金额查看
      toBeAssignClick(infoId, companyName) {
        this.$refs['ToBeAssignModel'].showAddEdit(infoId, companyName)
      },
    },
  }
</script>

<style scoped lang="scss">
  .client-msg {
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    // 左侧
    .client-left {
      border: 1px solid #ededed;
      padding: 25px 15px;
      height: 100vh;
      // top
      .client-left-first {
        > .el-row + .el-row {
          margin-top: 15px;
        }
        .split-50-row {
          > .el-row {
            width: 50%;
          }
        }
      }
      // middle
      .client-left-second {
        border-top: 1px solid #ededed;
        margin-top: 20px;
        padding-top: 20px;
        > .el-row {
          text-align: center;
          width: 50%;
          span {
            font-size: 17px;
            color: #0486fe;
            text-decoration: underline;
            font-weight: bold;
            cursor: pointer;
          }
          p {
            margin-top: 8px;
          }
          .el-button {
            margin-top: 15px;
          }
        }
        > .el-row + .el-row {
          > .el-row {
            border-left: 1px solid #ededed;
          }
        }
      }
      // bottom
      .client-left-three {
        border-top: 1px solid #ededed;
        margin-top: 20px;
        padding-top: 20px;
        > .el-row {
          p {
            font-size: 16px;
            font-weight: bold;
            margin-top: 10px;
            word-wrap: break-word;
          }
        }
        > .el-row + .el-row {
          margin-top: 20px;
        }
      }
    }
    //右侧
    .clent-right {
      border: 1px solid #ededed;
      padding: 25px 15px;
      height: 100vh;
      .floor {
        .p-title {
          margin-bottom: 15px;
        }
      }
      .floor + .floor {
        margin-top: 30px;
      }
      .roll-box {
        height: 223px;
        .el-table {
          overflow: hidden;
          overflow-y: auto;
          margin-bottom: 10px;
        }
      }
    }
  }
  .follows-table {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
  }
  .u160 {
    color: #8d8f92;
  }
  .no-text {
    font-weight: normal !important;
    color: #909399 !important;
    font-size: 14px !important;
  }
  .no-assign {
    font-weight: normal !important;
    color: #909399 !important;
    text-decoration: none !important;
  }
  .person-span {
    display: inline-block;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  // 邮箱链接
  .prod-code {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }
  .h6-tooltip {
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
